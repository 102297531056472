import React from 'react'
import { Button as RBButton, ButtonProps } from 'react-bootstrap'
import styled from 'styled-components'

interface Props extends ButtonProps {
    color: string
    hover?: string
    onClick?: () => void
    disabled?: boolean
}

const OutlineButton = styled(RBButton)`
    color: ${({ color }) => color};
    border-color: ${({ color }) => color};
    &:focus {
        color: ${({ color }) => color} !important;
        // color: #fff !important;
        border-color: ${({ color }) => color} !important;
    }
    &:active {
        background-color: ${({ color }) => color} !important;
        border-color: ${({ color }) => color} !important;
        color: #fff !important;
    }
    &:hover {
        background-color: ${({ color }) => color};
        color: #fff !important;
        border-color: ${({ color }) => color};
    }
    &:disabled {
        background-color: ${({ color }) => color};
        border-color: ${({ color }) => color};
        color: #fff;
    }
`

const StandardButton = styled(RBButton)`
    background-color: ${({ color }) => color};
    border-color: ${({ color }) => color};
    &:focus,
    &:active {
        background-color: ${({ color }) => color} !important;
        border-color: ${({ color }) => color} !important;
    }
    &:hover {
        background-color: ${({ hover }) => hover};
        border-color: ${({ hover }) => hover};
    }
    &:disabled {
        background-color: ${({ hover }) => hover};
        border-color: ${({ hover }) => hover};
    }
`

function Button(props: React.PropsWithChildren<Props>) {
    const { color, hover, children, onClick, disabled, ...rest } = props
    const outline = !!rest.variant?.match(/outline-/)

    if (outline) {
        return (
            <OutlineButton color={color} onClick={onClick} {...rest} disabled={disabled}>
                {children}
            </OutlineButton>
        )
    }

    return (
        <StandardButton color={color} onClick={onClick} hover={hover || color} disabled={disabled} {...rest}>
            {children}
        </StandardButton>
    )
}

export default Button
