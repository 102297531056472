import React, { useState, useRef, useCallback } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, FormControl, Row, ProgressBar } from 'react-bootstrap'
import FormGroup from '../FormGroup'
import FormTextArea from '../FormTextArea'
import Button from '../Button'
import Booth from '../../templates/Booth'
import { Colors } from '../../helpers/theme'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { generalCreateBooth } from '../../custom-graphql/myQueries'
import { CreateBoothInput } from '../../API'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { Category } from '../../API'
import { uploadImage } from '../../helpers/s3-upload'

const schema = yup.object().shape({
    title: yup.string().label('Title').required(),
    description: yup.string().label('Description').required(),
    videoLink: yup.string(),
    images: yup.array().of(yup.mixed()),
    logo: yup.array().of(yup.mixed()),
    facebookUsername: yup.string(),
    instagramHandle: yup.string(),
    website: yup.string(),
})

const FormHelpText = styled.div`
    display: flex;
    flex-direction: column;
`

const DropArea = styled.div`
    background-color: #eeeeee;
    border-radius: 1em;
    height: 5em;
    border: #999999 dotted 4px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        margin: 0;
        font-weight: 700;
    }
`
const Header = styled.div`
    background: ${Colors.fairRed};
    color: ${Colors.ivory};
    padding: 0.5em;
    margin: 1px;
    border-radius: 5px;
`

const CreateContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @media (min-width: 850px) {
        flex-direction: row;
        justify-content: space-between;
    }
`
const FormContainer = styled.div`
    width: auto;
    min-width: 375px;
`

const BoothPreview = styled.div`
    min-width: 375px;
    width: 40%;
    // width: 375px;
`
const ProgressBarContainer = styled.div`
    padding-top: 0.5em;
    padding-bottom: 1em;
`

interface DropzoneProps {
    setValue: (value: any, shouldValidate?: boolean) => void
    text: string
    limit?: boolean
}

const FileList = styled.ul`
    text-decoration: none;
    padding-left: 0;
`

const FileListItem = styled.li`
    text-decoration: none;
    display: flex;
    width: 100%;
    margin: 0.5em;
`

const DZRemove = styled.div`
    background-color: red;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #efefef;
    margin-left: auto;
    margin-right: 0.5em;
    border-radius: 4px;
    justify-self: flex-end;
    align-self: flex end;
`

function FileDropZone({ setValue, text, limit }: DropzoneProps) {
    const [myFiles, setMyFiles] = useState<File[]>([])

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (limit) {
                setMyFiles(acceptedFiles.length > 0 ? [acceptedFiles[0]] : myFiles)
                setValue(acceptedFiles.length > 0 ? [acceptedFiles[0]] : myFiles)
            } else {
                setMyFiles([...myFiles, ...acceptedFiles])
                setValue([...myFiles, ...acceptedFiles])
            }
        },
        [myFiles],
    )

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop,
    })

    const files = myFiles.map((file) => (
        <FileListItem key={file.name}>
            {file.name} - {file.size} bytes
            <DZRemove
                color="red"
                onClick={() => {
                    setMyFiles(myFiles.filter((x) => x.name !== file.name))
                }}
            >
                X
            </DZRemove>
        </FileListItem>
    ))

    return (
        <section>
            <DropArea {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>{text}</p>
            </DropArea>
            <aside>
                <p>Files</p>
                <FileList>{files}</FileList>
            </aside>
        </section>
    )
}

let progressVals: number[] = []

function BoothForm() {
    const history = useHistory()
    const [progress, setProgress] = useState(0)
    function handleProgress({ loaded, total }: { loaded: number; total: number }, index: number) {
        const prog = (loaded / total) * 100
        progressVals[index] = prog
        console.log({ progressVals })
        const average = Math.round(
            progressVals.reduce((acc, curr) => {
                if (isNaN(Number(curr))) return acc
                return acc + Number(curr)
            }, 0) / progressVals.length,
        )
        console.log({ average })
        if (progress < average) {
            setProgress(average)
        }
    }
    return (
        <Formik
            initialValues={{
                category: Category.BUSINESS,
                title: '',
                description: '',
                videoLink: '',
                facebookUsername: '',
                instagramHandle: '',
                website: '',
                images: [] as File[],
                logo: [] as File[],
            }}
            validationSchema={schema}
            onSubmit={async (
                { category, title, description, videoLink, images, instagramHandle, website, facebookUsername, logo },
                { setSubmitting, resetForm },
            ) => {
                try {
                    setSubmitting(true)
                    console.log({
                        category,
                        title,
                        description,
                        videoLink,
                        images,
                        instagramHandle,
                        website,
                    })
                    progressVals = []
                    progressVals = new Array(images.length).fill(0)
                    const imagePaths = images.map((image, index) =>
                        uploadImage(image, (prog) => handleProgress(prog, index)),
                    )
                    let logoPath = ''
                    const identity = (await Auth.currentCredentials()).identityId
                    if (logo[0]) {
                        const logoKey = await uploadImage(logo[0])
                        logoPath = `protected/${identity}/${(logoKey as any)['key']}`
                    }
                    const imageKeys = await Promise.all(imagePaths)
                    const boothInput: CreateBoothInput = {
                        title: title || '',
                        description: description || '',
                        logo: logoPath,
                        category,
                        youtubeUrl: videoLink,
                        instagramHandle,
                        website,
                        facebookId: facebookUsername,
                        images: imageKeys.map((entry) => {
                            console.log({ s3Response: entry })
                            if ('key' in entry) return `protected/${identity}/${entry['key']}`
                            else return ''
                        }),
                    }
                    const response = await API.graphql(graphqlOperation(generalCreateBooth, { input: boothInput }))
                    console.log({ response })

                    const id = (response as any).data.createBooth.id
                    history.push(`/booth/${id}`)
                } catch (err) {
                    console.error(err)
                } finally {
                    setSubmitting(false)
                }
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                isValid,
                isSubmitting,
                setFieldValue,
            }) => (
                <CreateContainer>
                    <FormContainer>
                        <Header>
                            <h1>Create your Booth!</h1>
                        </Header>
                        <Form noValidate onSubmit={handleSubmit} action={'#'}>
                            <FormGroup
                                id="category"
                                label="I am a"
                                type="select"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.category}
                                error={errors.category}
                                touched={touched.category}
                            >
                                {[
                                    { value: Category.BUSINESS, title: 'Business' },
                                    { value: Category.ATTENDEE, title: 'Non-Profit Organization' },
                                    { value: Category.ENTERTAINER, title: 'Entertainer' },
                                    { value: Category.AGRICULTURE, title: 'Agricultural Organization' },
                                ].map((item) => (
                                    <option key={item.value} value={item.value}>
                                        {item.title}
                                    </option>
                                ))}
                            </FormGroup>
                            <FormGroup
                                id="title"
                                label="Title"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.title}
                                error={errors.title}
                                touched={touched.title}
                            />
                            <FileDropZone
                                setValue={(value, shouldValidate) => {
                                    setFieldValue('logo', value, shouldValidate)
                                }}
                                limit
                                text={'Upload a logo. Only first selection will be used.'}
                            />
                            <FormTextArea
                                id="description"
                                label="Description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                rows={5}
                                value={values.description}
                                error={errors.description}
                                touched={touched.description}
                            />
                            <FileDropZone
                                setValue={(value, shouldValidate) => setFieldValue('images', value, shouldValidate)}
                                text="Add photos to your Booth's carousel"
                            />
                            <FormGroup
                                id="videoLink"
                                label="Youtube or Facebook video Link"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.videoLink}
                                error={errors.videoLink}
                                touched={touched.videoLink}
                            />
                            <FormHelpText>
                                <FormGroup
                                    id="facebookUsername"
                                    label="Facebook Username"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.facebookUsername}
                                    error={errors.facebookUsername}
                                    touched={touched.facebookUsername}
                                />
                                <p>
                                    {
                                        'To find your Facebook page username, go to your facebook page, look in the address bar and copy the username portion: https://facebook.com/{username}. For example: https://facebook.com/ADFallFair => ADFallFair'
                                    }
                                </p>
                            </FormHelpText>
                            <FormGroup
                                id="instagramHandle"
                                label="Instagram Username"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.instagramHandle}
                                error={errors.instagramHandle}
                                touched={touched.instagramHandle}
                            />
                            <FormGroup
                                id="website"
                                label="Website URL"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.website}
                                error={errors.website}
                                touched={touched.website}
                            />
                            <Button
                                color={Colors.fairRed}
                                hover={Colors.mango}
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting || !isValid}
                            >
                                Submit
                            </Button>
                            {isSubmitting && (
                                <ProgressBarContainer>
                                    <ProgressBar animated now={progress} label={`Submitting - ${progress}%`} />
                                </ProgressBarContainer>
                            )}
                        </Form>
                    </FormContainer>
                    <BoothPreview>
                        <h1>Preview:</h1>
                        <Booth
                            boothData={{
                                ...values,
                                id: '',
                                owner: '',
                                youtubeUrl: values.videoLink,
                                images: [],
                                livestream: '',
                                logo: '',
                                facebookId: values.facebookUsername,
                            }}
                            altImages={values.images}
                            altLogo={values.logo[0]}
                        />
                    </BoothPreview>
                </CreateContainer>
            )}
        </Formik>
    )
}

export default BoothForm
