import React from 'react'
import { Container, Button } from 'react-floating-action-button'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from '../helpers/theme'

const ButtonImage = styled.img`
    width: 100%;
`

const ButtonText = styled.span`
    font-size: 2em;
`
const ButtonStyles = {
    color: Colors.ivory,
    backgroundColor: Colors.green,
}

function ActionButton() {
    const history = useHistory()

    return (
        <Container styles={{ bottom: '1rem', right: '1rem' }}>
            <Button tooltip="Discover" styles={ButtonStyles} onClick={() => history.push('/')}>
                <ButtonImage src={require('../assets/MyFairLogo.svg')} />
            </Button>
            <Button tooltip="My Booths" styles={ButtonStyles} onClick={() => history.push('/booths/my')}>
                <ButtonImage style={{ width: '100%' }} src={require('..//assets/Booth.svg')} />
            </Button>
            <Button tooltip="About" styles={ButtonStyles} onClick={() => history.push('/about')}>
                <ButtonText>?</ButtonText>
            </Button>
            <Button rotate={true} styles={ButtonStyles}>
                <ButtonText>+</ButtonText>
            </Button>
        </Container>
    )
}

export default ActionButton
