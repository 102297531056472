import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import EditBoothForm from '../../molecules/vendor/EditBoothForm'
import Booth from '../../templates/Booth'
import { Auth, API } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { generalGetBooth } from '../../custom-graphql/myQueries'
import { BoothData } from '../../data/mock'
import { GetBoothQuery } from '../../API'
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api/lib-esm'

const Form = styled.div`
    padding: 1em;
`

interface Props {
    id: string
}

function Edit({ id }: Props) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [booth, setBooth] = useState<BoothData | null>(null)
    useEffect(() => {
        setLoading(true)
        ;(async function () {
            try {
                const response = (await API.graphql({
                    query: generalGetBooth,
                    variables: { id },
                })) as GraphQLResult<GetBoothQuery>
                if (response.errors) {
                    console.error({ errors: response.errors })
                    setLoading(false)
                    setError(true)
                    return
                }
                if (response.data && response.data.getBooth) {
                    const {
                        id,
                        owner,
                        category,
                        title,
                        description,
                        youtubeUrl,
                        images,
                        livestream,
                        facebookId,
                        instagramHandle,
                        website,
                        logo,
                    } = response.data.getBooth

                    setBooth({
                        id,
                        owner,
                        category,
                        title,
                        description,
                        youtubeUrl,
                        images,
                        livestream,
                        facebookId,
                        instagramHandle,
                        website,
                        logo,
                    })
                }
            } catch (err) {
                console.error({ err })
                setError(true)
            } finally {
                setLoading(false)
            }
        })()
    }, [])
    return <Form>{!loading && !error && <EditBoothForm initialValues={{ ...booth }} />}</Form>
}

export default Edit
