import React from 'react'
import styled from 'styled-components'

const Page = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const FormContainer = styled.div`
    width: min(16em, 95%);
    max-width: 16em;
    margin-top: 3em;
`

interface Props {
    Form: () => JSX.Element
}

export default function Authentication({ Form }: React.PropsWithChildren<Props>) {
    return (
        <Page>
            <FormContainer>
                <Form />
            </FormContainer>
        </Page>
    )
}
