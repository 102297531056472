/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRegister = /* GraphQL */ `
  mutation CreateRegister(
    $input: CreateRegisterInput!
    $condition: ModelRegisterConditionInput
  ) {
    createRegister(input: $input, condition: $condition) {
      owner
      type
      altEmail
      businessName
      createdAt
      updatedAt
    }
  }
`;
export const updateRegister = /* GraphQL */ `
  mutation UpdateRegister(
    $input: UpdateRegisterInput!
    $condition: ModelRegisterConditionInput
  ) {
    updateRegister(input: $input, condition: $condition) {
      owner
      type
      altEmail
      businessName
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegister = /* GraphQL */ `
  mutation DeleteRegister(
    $input: DeleteRegisterInput!
    $condition: ModelRegisterConditionInput
  ) {
    deleteRegister(input: $input, condition: $condition) {
      owner
      type
      altEmail
      businessName
      createdAt
      updatedAt
    }
  }
`;
export const updateEmail = /* GraphQL */ `
  mutation UpdateEmail(
    $input: UpdateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    updateEmail(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateGroceryEntry = /* GraphQL */ `
  mutation UpdateGroceryEntry(
    $input: UpdateGroceryEntryInput!
    $condition: ModelGroceryEntryConditionInput
  ) {
    updateGroceryEntry(input: $input, condition: $condition) {
      submission
      email
      phone
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroceryEntry = /* GraphQL */ `
  mutation DeleteGroceryEntry(
    $input: DeleteGroceryEntryInput!
    $condition: ModelGroceryEntryConditionInput
  ) {
    deleteGroceryEntry(input: $input, condition: $condition) {
      submission
      email
      phone
      name
      createdAt
      updatedAt
    }
  }
`;
export const createBooth = /* GraphQL */ `
  mutation CreateBooth(
    $input: CreateBoothInput!
    $condition: ModelBoothConditionInput
  ) {
    createBooth(input: $input, condition: $condition) {
      id
      owner
      category
      email
      reviewed
      title
      description
      livestream
      logo
      images
      youtubeUrl
      instagramHandle
      facebookId
      website
      createdAt
      updatedAt
    }
  }
`;
export const updateBooth = /* GraphQL */ `
  mutation UpdateBooth(
    $input: UpdateBoothInput!
    $condition: ModelBoothConditionInput
  ) {
    updateBooth(input: $input, condition: $condition) {
      id
      owner
      category
      email
      reviewed
      title
      description
      livestream
      logo
      images
      youtubeUrl
      instagramHandle
      facebookId
      website
      createdAt
      updatedAt
    }
  }
`;
export const deleteBooth = /* GraphQL */ `
  mutation DeleteBooth(
    $input: DeleteBoothInput!
    $condition: ModelBoothConditionInput
  ) {
    deleteBooth(input: $input, condition: $condition) {
      id
      owner
      category
      email
      reviewed
      title
      description
      livestream
      logo
      images
      youtubeUrl
      instagramHandle
      facebookId
      website
      createdAt
      updatedAt
    }
  }
`;
export const createEmail = /* GraphQL */ `
  mutation CreateEmail(
    $input: CreateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    createEmail(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmail = /* GraphQL */ `
  mutation DeleteEmail(
    $input: DeleteEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    deleteEmail(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const createGroceryEntry = /* GraphQL */ `
  mutation CreateGroceryEntry(
    $input: CreateGroceryEntryInput!
    $condition: ModelGroceryEntryConditionInput
  ) {
    createGroceryEntry(input: $input, condition: $condition) {
      submission
      email
      phone
      name
      createdAt
      updatedAt
    }
  }
`;
