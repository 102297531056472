import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface Props {
    className?: string
}

const ImageHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
const TextHolder = styled.div`
    display: flex;
    justify-content: center;
    padding-right: 8em;
`

export default function CombinedLogo({ className }: Props) {
    return (
        <Link to="/" style={{ color: 'inherit', textDecoration: 'none', paddingTop: '1em' }}>
            <ImageHolder>
                <img src={require('../assets/FallFairLogo.png')} style={{ height: '5em' }} />
                <span style={{ fontSize: '4em' }}>&#43;</span>
                <img src={require('../assets/MyFairLogo.svg')} style={{ height: '5em' }} />
            </ImageHolder>
            <TextHolder>
                <span style={{ width: '8em', fontWeight: 'bold' }}>Alberni Valley Virtual Fair</span>
                <span style={{ width: '4em', paddingLeft: '0.1em', borderLeft: '0.1em solid black' }}>
                    September 10-12
                </span>
            </TextHolder>
        </Link>
    )
}
