import { Recipe as R } from '.'

const BruleedBuffaloBrie: R = {
    title: 'Bruleed Buffalo Brie with Tomatoe Ginger Jam',
    ingredients: [
        '1 baguette, sliced into rounds',
        'Natural Pastures Buffalo Brie ',
        '8 tomatoes, coarsely chopped ',
        '1 cup sugar ',
        'Zest and juice of 1 lemon ',
        '1 Tbsp ginger, minced ',
        '1 tsp ground cumin ',
        'Pinch of ground cloves ',
        '1/2 tsp salt ',
        '1/4 tsp red pepper flakes ',
    ],
    steps: [
        'Combine ingredients for tomato jam in a saucepot and bring to a boil over medium heat. Reduce to low and cook, stirring often, until moisture has evaporated and the mixture is the consistency of thick jam',
        'Top baguette slices with a generous piece of Natural Pastures Buffalo Brie. Broil cheese under high heat in the oven until golden and bubbling, about 30 seconds.',

        'Top with tomato ginger jam and serve. ',
    ],
    farm: 'Coleman Meadows Farm',
}

const BreadedWaterBuffalo: R = {
    title: 'Breaded Water Buffalo Paneer Snack',
    ingredients: [
        '1 egg ',
        '2 Tbsp. water ',
        '1 package Natural Pastures Paneer',
        '¾ cup breadcrumbs ',
        'Pinch each of salt, pepper, and cayenne',
        '1 Tbsp. butter',
    ],
    steps: [
        'Whisk together egg and water in a small bowl.',
        'Cut paneer into 1-inch pieces, and dip into egg mixture, then roll in seasoned breadcrumbs. ',
        'Heat skillet with butter and fry paneer until crispy',
        'Serve with a fabulous (of your choice) dipping sauce! An idea is a Jamaican spicy sauce, mix with sour cream and a bit of mayo or a spicy Chipotle…the ideas are endless, and it is so good!',
    ],
    farm: 'Coleman Meadows Farm',
}

const BuffaloMozza: R = {
    title: 'Fresh Water Buffalo Mozzarella and Tomato Pizza with Basil-Garlic Sauce',
    ingredients: [
        '2 medium fresh tomatoes; cut in thin slices ',
        '1 cup fresh basil ',
        '¼ cup extra-virgin olive oil',
        '3 cloves garlic; smashed',
        'pinch pepper',
        'hot pepper flakes (optional)',
        '¼ cup grated Parmesan cheese',
        '1 prepared pizza dough (better yet, make your own)',
        '1 tub Natural Pastures Water Buffalo Mozzarella, drained and sliced',
    ],
    steps: [
        'Leave tomato slices to dry on clean tea towel or paper towel.',
        'Puree basil, oil, garlic, pepper, hot pepper flakes, stir in Parmesan cheese.',
        'Shape pizza dough into 14-inch round, spread basil sauce over surface and top with tomatoes ',
        'Bake 15-17min on parchment paper- lined pizza pan in a 450F oven (until bottom of crust is golden).Top with mozzarella cheese and return to oven bake until melted (about 3 min).',
        'Let it cool just enough and enjoy!',
        'Add slices of our water buffalo sausage to add another flavour to your pizza!',
    ],
    farm: 'Coleman Meadows Farm',
}

const BuffaloCrostini: R = {
    title: 'Water Buffalo Mozzarella Crostini',
    ingredients: [
        '2 cups (500 mL) diced fresh tomatoes',
        '1 tbsp. (15 mL) extra-virgin olive oil',
        '1/4 tsp. (1 mL) pepper',
        'Fresh basil',
        '1 tub Natural Pastures water buffalo bocconcini or mozzarella di bufala',
        '1/4 cup (60 mL) prepared basil pesto',
        '24 slices of baguette or crusty bread',
    ],
    steps: [
        'In bowl, toss together tomatoes, oil, pepper and basil. Roast on parchment paper–lined baking sheet at 400°F (200°C) oven, stirring once, until shriveled, about 40 minutes.',
        'On a baking sheet, broil baguette turning once, until golden, about 1 minute. Cut bocconcini or large mozzarella ball cheese into 24 thin slices; pat cheese dry. Spread each crostini with 1/2 tsp. (2 mL) pesto; top with cheese. Spoon tomatoes evenly over top.',
    ],
    farm: 'Coleman Meadows Farm',
}
export const DairyRecipes = [BruleedBuffaloBrie, BreadedWaterBuffalo, BuffaloMozza, BuffaloCrostini]
export { BruleedBuffaloBrie, BreadedWaterBuffalo, BuffaloMozza, BuffaloCrostini }
