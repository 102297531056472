import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { BoothData } from '../data/mock'
import facebookLogo from '../assets/FacebookLogoBlue.svg'
import messengerLogo from '../assets/Messenger.svg'
import instagramLogo from '../assets/InstagramLogo.svg'
import { Button, Carousel } from 'react-bootstrap'
import { Colors } from '../helpers/theme'
import YoutubePlayer from '../molecules/YoutubePlayer'
import IsLiveButton from '../molecules/IsLiveButton'
import FacebookPlayer from '../molecules/FacebookPlayer'

interface Props {
    boothData: BoothData
    altImages?: File[]
    altLogo?: File
}

const Title = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 1em;
    h1 {
        margin-left: 1em;
        font-size: 2em;
    }
`

const Logo = styled.div`
    width: 25%;
    height: auto;
    margin-left: auto;
    justify-self: flex-end;
    img {
        width: 100%;
        border-radius: 4px;
    }
`

const CarouselContainer = styled.div`
    height: 35vh;
`

const LiveInfo = styled.div``

const About = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    background-color: #cccccc;
    border: 1px #888888 solid;
    border-radius: 0.5em;
    width: 90%;
    h4 {
        padding-top: 0.2em;
        margin-bottom: 0;
        padding-left: 0.5em;
        padding-right: 1em;
        font-size: 1.5em;
        padding-bottom: 0;
        font-weight: 700;
        color: #555555;
    }
    #about-content {
        border-radius: 0.5em;
        border-top: 1px #888888 solid;
        display: flex;
        align-items: flex-start;
        background-color: #fefefe;
        padding-left: 0.5em;
        width: 100%;
        padding-right: 0.5em;
    }
`

const Discover = styled.div``

const Website = styled.div`
    width: 65%;
    #website-link {
        border-color: ${Colors.fairRed};
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        color: ${Colors.ivory};
        background-color: ${Colors.fairRed};
        &:hover {
            border-color: ${Colors.mango};
            background-color: ${Colors.mango};
        }
    }
`

const SocialButtons = styled.div`
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    height: 5em;
    padding: 0.5em;
    div {
        height: 100%;
    }
    #facebook-link {
        height: 100%;
        img {
            height: 100%;
        }
        margin-right: 1em;
    }
    #instagram-link {
        height: 100%;
        img {
            height: 100%;
        }
    }

    #messenger-link {
        display: inline;
        border-radius: 4px;
        border: 1px #ccc solid;
        background: #fefefe;
        height: 100%;
        text-decoration: none;
        color: #000;
        padding-right: 0.5em;
        &:hover {
            text-decoration: none;
        }
        img {
            height: 100%;
            padding: 0.5em;
        }
    }
`

const BoothWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 95%;
    @media (min-width: 550px) {
        max-width: 700px;
    }
    background-color: ${Colors.ivory};
    padding: 10px;
    margin: 20px;
`
const YoutubeRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/

function getPlayerDimensions(): { height: string; width: string } {
    const desiredHeight = document.documentElement.clientHeight * 0.35
    const widthBasedOnHeight = (desiredHeight * 16) / 9
    const desiredWidth = document.documentElement.clientWidth
    const heightBasedOnWidth = (desiredWidth * 9) / 16

    if (widthBasedOnHeight < desiredWidth) {
        return { height: desiredHeight.toString(), width: widthBasedOnHeight.toString() }
    }
    return { height: heightBasedOnWidth.toString(), width: desiredWidth.toString() }
}

function getFacebookId(userInput: string) {
    const match = userInput.match(/facebook\.com\/([^\/]+)\/?/)
    if (match) return match[1]
    return userInput
}

function Booth({ boothData, altImages, altLogo }: Props) {
    const logoRef = useRef<HTMLImageElement>(null)
    const shareButtonRef = useRef<HTMLDivElement>(null)
    const [showCarouselControls, setCarouselControls] = useState(true)
    const [slideInterval, setSlideInterval] = useState<number | null>(5000)

    useEffect(() => {
        FB.XFBML.parse(shareButtonRef.current)
    }, [])

    if (altLogo) {
        const reader = new FileReader()
        reader.onload = (e) => {
            if (logoRef.current) logoRef.current.src = (e.target?.result as string) || ''
        }
        reader.readAsDataURL(altLogo)
    }
    const { height: playerHeight, width: playerWidth } = getPlayerDimensions()
    return (
        <BoothWrapper>
            <Title>
                <h1>{boothData.title}</h1>
                <Logo>
                    <img style={{}} ref={logoRef} src={`https://myfair.ca/${boothData.logo}`} />
                </Logo>
            </Title>
            <CarouselContainer>
                <Carousel controls={showCarouselControls} interval={slideInterval}>
                    {boothData.youtubeUrl && (
                        <Carousel.Item>
                            {(() => {
                                /* Just going to assume if it is not a YT video, then it's a facebook video */
                                if (boothData.youtubeUrl.match(YoutubeRegex)) {
                                    return (
                                        <YoutubePlayer
                                            height={playerHeight}
                                            width={playerWidth}
                                            videoId={boothData.youtubeUrl.split('watch?v=')[1]}
                                            onPlay={() => {
                                                setCarouselControls(false)
                                                setSlideInterval(null)
                                            }}
                                            onPause={() => {
                                                setCarouselControls(true)
                                                setSlideInterval(5000)
                                            }}
                                            onEnd={() => {
                                                setCarouselControls(true)
                                                setSlideInterval(5000)
                                            }}
                                        />
                                    )
                                } else {
                                    return (
                                        <FacebookPlayer
                                            videoLink={boothData.youtubeUrl}
                                            height={playerHeight}
                                            width={playerWidth}
                                        />
                                    )
                                }
                            })()}
                        </Carousel.Item>
                    )}
                    {boothData.images.map((image) => (
                        <Carousel.Item key={image}>
                            <img
                                src={`https://myfair.ca/${image}`}
                                style={{ width: 'auto', maxHeight: '35vh', maxWidth: '95%', height: 'auto' }}
                            />
                        </Carousel.Item>
                    ))}
                    {altImages?.map((file) => {
                        const reader = new FileReader()
                        const imgRef = React.createRef<HTMLImageElement>()
                        reader.readAsDataURL(file)
                        reader.onload = (e: ProgressEvent<FileReader>) => {
                            if (imgRef.current) imgRef.current.src = (e.target?.result as string) || ''
                        }
                        return (
                            <Carousel.Item>
                                <img
                                    key={file.name}
                                    ref={imgRef}
                                    style={{ width: 'auto', maxHeight: '35vh', maxWidth: '95%', height: 'auto' }}
                                />
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </CarouselContainer>
            <LiveInfo></LiveInfo>
            <Website ref={shareButtonRef}>
                <Button
                    id="website-link"
                    as="a"
                    href={boothData.website || undefined}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Website
                </Button>
                {/* <IsLiveButton rssUrl="http://fetchrss.com/rss/5f56c6803c140278522985135f56c72f61579219a908f6e2.json"/> */}
                {boothData.livestream && <IsLiveButton rssUrl={boothData.livestream} />}
                <div
                    className="fb-share-button"
                    data-href={`https://myfair.ca/booth/${boothData.id}`}
                    data-layout="button_count"
                    data-size="large"
                >
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
                        className="fb-xfbml-parse-ignore"
                    >
                        Share
                    </a>
                </div>
            </Website>
            <SocialButtons>
                <div>
                    {boothData.facebookId && (
                        <a
                            id="facebook-link"
                            href={
                                !!boothData.facebookId.match(/facebook\.com/)
                                    ? boothData.facebookId
                                    : `https://facebook.com/${boothData.facebookId}`
                            }
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img src={facebookLogo} />
                        </a>
                    )}
                    {boothData.instagramHandle && (
                        <a
                            href={
                                !!boothData.instagramHandle.match(/instagram\.com/)
                                    ? boothData.instagramHandle
                                    : `https://instagram.com/${boothData.instagramHandle}`
                            }
                            target="_blank"
                            rel="noreferrer noopener"
                            id="instagram-link"
                        >
                            <img src={instagramLogo} />
                        </a>
                    )}
                </div>
                {boothData.facebookId && (
                    <a
                        id="messenger-link"
                        href={`https://m.me/${getFacebookId(boothData.facebookId)}`}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <img src={messengerLogo} />
                        chat with us!
                    </a>
                )}
            </SocialButtons>
            {boothData.id == 'f68edb32-30b4-40c5-bc5a-50253639067c' && (
                <Button
                    onClick={() =>
                        window.open(
                            'https://www.facebook.com/watch/live/?extid=GuqIxT9qzos9GhCY&v=1005246829940393&ref=watch_permalink',
                        )
                    }
                    style={{ marginBottom: '1.5em' }}
                >
                    Check out live feed
                </Button>
            )}
            <About>
                <h4>About</h4>
                <div id="about-content">
                    <p>{boothData.description}</p>
                </div>
            </About>
            <Discover></Discover>
        </BoothWrapper>
    )
}

export default Booth
