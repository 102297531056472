import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../../molecules/Button'
import { Colors } from '../../helpers/theme'

const Heading = styled.div``

const Content = styled.div``

const NavButton = styled.div``

const Container = styled.div`
    color: red;
`

const textBodies = [
    `We're using Facebook Live streaming and the Myfair.ca website to host a virtual fair!, registration is free for non-business organizations/individuals, and $50 for businesses`,
    `Live streaming is when video is sent over the Internet in real time, without being recorded and stored first. It's like a live radio or TV broadcast, but over the internet.`,
    `Using Facebook Live, live-booth holders (like you) will be able to interact with Facebook audiences in real time, just like you would at a real fair!`,
    `Whats more, is that the people you interact with will have a direct link to your website and social media channels! So make sure you're pasting a link to your livebooth in the description of your Facebook Live stream, and that you’re encouraging audiences to click into it!`,
    `Your booth will be live for on the virtual fair website 3 days from September 10-12th. You may go live as much or a little as you want during this time, but peak hours will be from 12 pm to 3 pm daily, so make sure you’re live between those times to get the most out of your stream!`,
    `To get your live booth set up, we're going to ask you to provide a bit of information about your endeavour.`,
]

const textHeadings = [
    `Thank you for participating in the Alberni District Virtual Fair!`,
    `What is Live Streaming?`,
    'What is so Good About Live Streaming?',
    'What is so Good About Live Streaming?',
    'When Should you go Live?',
    'Time to Build Your Booth!',
]

function GettingStarted() {
    const [index, setIndex] = useState(0)
    return (
        <Container>
            {index !== 0 && (
                <NavButton onClick={() => setIndex(index + 1)}>
                    <Button variant="outline-primary" color={Colors.fairRed} hover={Colors.mango}></Button>
                </NavButton>
            )}
            <Heading>
                <span>{textHeadings[index % textHeadings.length]}</span>
            </Heading>
            <Content>
                <span>{textBodies[index]}</span>
            </Content>
            {index !== textBodies.length - 1 && (
                <NavButton onClick={() => setIndex(index - 1)}>
                    <Button variant="outline-primary" color={Colors.fairRed} hover={Colors.mango}></Button>
                </NavButton>
            )}
            {index === textBodies.length - 1 && <Button color={Colors.fairRed} hover={Colors.mango}></Button>}
        </Container>
    )
}

export default GettingStarted
