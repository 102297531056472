import React from 'react'
import { Form, InputGroup, Col, Row } from 'react-bootstrap'

interface Props {
    onChange: (value: number) => void
    value: number
}

function NumberInput({ onChange, value }: Props) {
    return (
        <InputGroup style={{ flexWrap: 'nowrap' }}>
            <InputGroup.Prepend>
                <InputGroup.Text onClick={() => onChange(value - 1)} id="inputGroupPrepend">
                    -
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                type="number"
                value={value}
                onChange={(e) => onChange(Number(e.target.value))}
                style={{ width: 'auto', maxWidth: '4em', textAlign: 'center' }}
            />
            <InputGroup.Append>
                <InputGroup.Text onClick={() => onChange(value + 1)} id="inputGroupAppend">
                    +
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    )
}

export default NumberInput
