import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { Colors } from '../helpers/theme'

const Wrapper = styled.div`
    @media (min-width: 850px) {
        width: 800px;
        margin: auto;
    }
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    #alt-login-text {
        padding-top: 1em;
        color: #888888;
        text-decoration: underline;
        display: inline-block;
        &:hover {
            color: #666666;
        }
    }

    h1 {
        color: ${Colors.fairRed};
        margin: 0.8em;
    }
    font-family: Roboto;
    font-weight: bold;
    font-styled: normal;
    font-size: 1.3em;
`

const SignInButton = styled(Button)`
    font-size: 1.5em;
    border-radius: 1.5em;
    padding: 0.8em;
`

export default function SignIn() {
    const history = useHistory()

    function handleSuccess() {
        history.push('/')
    }

    async function handleSignin() {
        const hash = window.location.hash
        try {
            const result = await Auth.federatedSignIn()
            console.log({ loginResult: result })
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        // Auth.signOut().n(() => {
        Auth.currentAuthenticatedUser()
            .then((session) => {
                console.log({ session })
                handleSuccess()
            })
            .catch((err) => {
                console.error(err)
            })
        // })
    }, [])
    return (
        <Wrapper>
            <h1> Sign in to the Alberni Virtual Fair</h1>
            <SignInButton onClick={() => handleSignin()}> Sign in to MyFair </SignInButton>
        </Wrapper>
    )
}
