import React from 'react'
import { Auth } from 'aws-amplify'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

function SignOut() {
    const history = useHistory()
    function handleSignOut() {
        Auth.signOut()
            .then(() => {
                history.push('/')
            })
            .catch((err) => console.error(err))
    }
    return (
        <Button
            onClick={() => {
                handleSignOut()
            }}
        >
            Sign Out
        </Button>
    )
}

export default SignOut
