import React from 'react'
import styled from 'styled-components'
import CombinedLogo from '../../molecules/CombinedLogo'
import { Button } from 'react-bootstrap'

const Outer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;

    h2,
    h3 {
        margin-top: 0.8em;
    }

    @media (min-width: 850px) {
        width: 800px;
    }
`

const SignUpButton = styled(Button)`
    margin-top: 2em;
`

const Text = styled.div`
    font-size: 1.3em;
    padding-top: 1em;
    text-align: left;
`

const UnderlinedText = styled.h3`
    text-decoration: underline;
`
export default function LandingLiveStream() {
    return (
        <Outer>
            <CombinedLogo />
            <h2>Local Live Streams</h2>
            <h3>Go see what&apos;s special about your community!</h3>
            <UnderlinedText>September 10th-12th!</UnderlinedText>
            <img src={require('../../assets/DeviceBooth.svg')} style={{ height: '45em' }} />
            <Text>
                This year, we’re letting community members compete in people’s choice competitions. Whoever has the most
                votes for a given category, wins. It’s that simple.
            </Text>
            <SignUpButton>Coming Soon!</SignUpButton>
        </Outer>
    )
}
