import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import CombinedLogo from '../../molecules/CombinedLogo'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 850px) {
        width: 800px;
    }
`
const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1em 0;
    img {
        width: 40%;
    }
    p {
        margin: auto;
    }
`

const Header = styled.h1<{ size?: string }>`
    // text-decoration: underline;
    font-size: ${({ size }) => size || '3.5em'};
    // padding: 0.5em;
    padding: 0.5em 0;
`
const TopBar = styled(CombinedLogo)`
    display: flex;
    justify-content: center;
    align-items: center;
`

const TextBold = styled.p`
    font-size: 1.5em;
    font-weight: 700;
`
const TextNormal = styled.p`
    font-size: 1.5em;
`
const Booth = styled.img`
    padding-bottom: 1em;
`
const BoothForm = styled.img``

const Register = styled(Button)`
    margin-bottom: 1em;
`
const RegisterButton = React.memo(function RegisterButton({ onClick }: { onClick: () => void }) {
    return <Register onClick={onClick}>Get Started</Register>
})

function Action({ children }: React.PropsWithChildren<{}>) {
    return <ActionContainer>{children}</ActionContainer>
}

function LandingVendor() {
    const history = useHistory()
    return (
        <Container>
            <TopBar />
            <Header>Go Live and Convert Strangers into Supporters</Header>
            <TextNormal>
                Live Booths are your space for showcasing your business, organization, talent, or some other endeavour.
            </TextNormal>
            <TextNormal>
                When you create your Live Booth, you can connect your Facebook Page, schedule a Facebook Live Stream,
                and share links to your website and social media.
            </TextNormal>
            <TextNormal>
                Fair attendees will be able browse all of these Live Booths from the virtual fair website.
            </TextNormal>
            <RegisterButton onClick={() => history.push('/booth/create')} />
            <TextBold>
                We’re beta testing MyFair, a new platform that allows anyone to host virtual events using Facebook Live.
            </TextBold>
            <Booth src={require('../../assets/DeviceBooth.svg')} />
            <BoothForm />
            <Header size="3em">Share Together, Grow Together</Header>
            <TextBold>MyFair works best when we all go live togehter</TextBold>
            <Action>
                <img src={require('../../assets/People.svg')} />
                <TextNormal>
                    Community organizations create Live Booths through the MyFair website and share their Live Streams
                    to Facebook.
                </TextNormal>
            </Action>
            <Action>
                <TextNormal>
                    The Facebook Livestreams flood local social media and drive local traffic towards the Vitrual Fair
                    website.
                </TextNormal>
                <img src={require('../../assets/FacebookLive.svg')} />
            </Action>
            <Action>
                <img src={require('../../assets/Network.svg')} />
                <TextNormal>
                    Local organizations combine the power of their networks to reach a broader audience.
                </TextNormal>
            </Action>
            <RegisterButton onClick={() => history.push('/booth/create')} />
        </Container>
    )
}

export default LandingVendor
