import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'

const NotLive = styled.div`
    background: rgba(0, 0, 0, 0.3);
    border-radius: 1.9px;
    font-family: roboto;
    font-weight: bold;
    color: white;
    font-size: 0.9em;
    margin-top: 2px;
`

interface IsLiveProps {
    rssUrl: string
}

function IsLiveButton({ rssUrl }: IsLiveProps) {
    const [livestreamUrl, setLivestreamUrl] = useState('')
    // const history = useHistory()

    useEffect(() => {
        console.log(rssUrl)
        const requestOptions: RequestInit = {
            method: 'GET',
            redirect: 'follow',
        }
        const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/'

        fetch(CORS_PROXY + rssUrl, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                result.items.forEach((item: any) => {
                    if (item.title.includes('is live')) {
                        setLivestreamUrl(item.link)
                    }
                })
            })
            .catch((error) => console.log('error', error))
    }, [])

    if (livestreamUrl === '') {
        return (
            // <NotLive>Booth is not currently Livestreaming</NotLive>
            <div></div>
        )
    }
    return (
        <Button
            id="website-link"
            as="a"
            href={livestreamUrl || undefined}
            target="_blank"
            rel="noreferrer noopener"
            style={{ marginTop: '2px' }}
        >
            Go To Livestream
        </Button>
    )
}

export default IsLiveButton
