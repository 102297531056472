import React from 'react'
import './App.css'
import Shop from './pages/grocery/Shop'
import ReactGA from 'react-ga'
import Home from './pages/Home'
import SignUp from './pages/SignUp'
import ConfirmEmail from './pages/ConfirmEmail'
import SignIn from './pages/SignIn'
import Vendor from './pages/booth/Vendor'
import LandingPage from './pages/landingPages/LandingPage'
import LandingCompetition from './pages/landingPages/LandingCompetition'
import LandingLiveStream from './pages/landingPages/LandingLiveStream'
import MyRoutes from './pages/Routes/MyRoutes'
import MyBooths from './pages/MyBooths'

import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import BoothRoutes from './pages/Routes/BoothRoutes'
import SignOut from './pages/SignOut'
import Privacy from './pages/Privacy'
import Discover from './pages/Discover'
import Raffle from './pages/Raffle'
import ActionButton from './molecules/ActionButton'
import RecipeRoutes from './pages/Routes/RecipeRoutes'

function App() {
    ReactGA.initialize('UA-177822253-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/privacy">
                        <Privacy />
                    </Route>
                    <Route path="/shop" component={Shop} />
                    <Route path="/signup" component={SignUp} />
                    <Route path="/signin" component={SignIn} />
                    <Route path="/signout">
                        <SignOut />
                    </Route>
                    <Route path="/confirm-email" component={ConfirmEmail} />
                    <Route path="/vendor" component={Vendor} />
                    <Route path="/competitions">
                        <LandingCompetition />
                    </Route>
                    <Route path="/livestreams">
                        <LandingLiveStream />
                    </Route>
                    <Route path="/booth">
                        <BoothRoutes />
                    </Route>
                    <Route path="/My">
                        <MyRoutes />
                    </Route>
                    <Route path="/booths/my">
                        <MyBooths />
                    </Route>
                    {/* <Route path="/discover" component={Discover} /> */}
                    {/* <Route path="/raffle" component={Raffle} /> */}
                    <Route path="/recipes" component={RecipeRoutes} />
                    <Route path="/about" component={LandingPage} />
                    <Route path="/" component={Discover} />
                    {/* <Route path="/" component={Home} /> */}
                </Switch>
                <Route path="/" component={ActionButton} />
            </Router>
        </div>
    )
}

export default App
