import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import CombinedLogo from '../../molecules/CombinedLogo'
import { useHistory } from 'react-router-dom'
import FacebookVideo from '../../molecules/FacebookVideo'

const Outer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 90%;
    align-items: center;
    margin: auto;

    h1 {
        font-size: 3.5em;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
    }
`
const Img = styled.img`
    height: 4em;
    width: 4em;
`

const ImgHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff9696;
    border: 1.38841px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: -1.36118px 2.72237px 2.04178px rgba(0, 0, 0, 0.25);

    width: 8em;
    height: 8em;
    margin: 1em;
`

const Holder = styled.div`
    display: flex;
    flex-flow: column;
`

function LandingPage() {
    const history = useHistory()

    useEffect(() => {
        if (localStorage.getItem('amplify-redirected-from-hosted-ui')) {
            const redirectUrl = localStorage.getItem('signinRedirect')
            localStorage.removeItem('signinRedirect')
            localStorage.removeItem('amplify-redirected-from-hosted-ui')
            if (redirectUrl) history.replace(redirectUrl)
        }
    }, [])

    return (
        <Outer>
            <CombinedLogo />
            <h1 style={{ paddingTop: '1em' }}>Get Ready for the Alberni Valley Virtual Fair</h1>

            <span style={{ textDecoration: 'underline', fontSize: '1.5em' }}>September 10-12</span>
            <span style={{ paddingTop: '2em', fontSize: '1.3em' }}>
                This year, we’re bringing together our community by using Facebook, Interactive Livestreaming, and Fun
                Competitions for all ages.
            </span>
            <h1 style={{ paddingTop: '1em' }}>Learn More</h1>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Holder>
                    <Link to="/booth">
                        <ImgHolder>
                            <Img src={require('../../assets/Booth.svg')} />
                        </ImgHolder>
                    </Link>
                    Booths
                </Holder>
                <Holder>
                    <Link to="/competitions">
                        <ImgHolder>
                            <Img src={require('../../assets/Trophy.svg')} />
                        </ImgHolder>
                    </Link>
                    Competitions
                </Holder>
                <Holder>
                    <Link to="/livestreams">
                        <ImgHolder>
                            <Img src={require('../../assets/Balloon.svg')} />
                        </ImgHolder>
                    </Link>
                    Attendees
                </Holder>
            </div>
        </Outer>
    )
}

export default LandingPage
