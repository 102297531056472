import React from 'react'
import styled from 'styled-components'
import { Colors, color } from '../helpers/theme'
import Button from '../molecules/Button'
import { Link } from 'react-router-dom'
import { Recipe as ReceipeData } from '../recipes'

const RecipeContainer = styled.div`
    background-color: ${Colors.ivory};
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Header = styled.div`
    background-color: ${Colors.fairRed};
    color: ${Colors.ivory};
    width: 100%;
    padding: 2em;

    h1 {
        font-size: 4em;
    }
    h2 {
        font-size: 3em;
    }
    h3 {
        font-size: 3em;
    }
    p {
        font-size: 1.5em;
        font-weight: 700;
    }
    span {
        font-size: 2em;
    }
`

const Footer = styled.div`
    width: 100%;
    padding: 2em;
    background-color: ${Colors.fairRed};
    color: ${Colors.ivory};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const IngredientsWrapper = styled.div`
    font-size: 2em;
    padding-top: 1.5em;
    ul,
    ol {
        text-align: left;
        padding-top: 1.5;
    }

    width: 20em;

    @media (max-width: 850px) {
        width: 15em;
    }
`
function Recipe({ title, farm, ingredients, steps }: ReceipeData) {
    return (
        <RecipeContainer>
            <Header>
                <h1> {title}</h1>
                <h2>{farm}</h2>
            </Header>
            <IngredientsWrapper>
                <h3>Ingredients:</h3>
                <ul>
                    {ingredients.map((ingredient) => (
                        <li key={ingredient}>{ingredient}</li>
                    ))}
                </ul>
            </IngredientsWrapper>
            <IngredientsWrapper>
                <h3>Instructions: </h3>
                <ol>
                    {steps.map((step) => (
                        <li key={step}>{step}</li>
                    ))}
                </ol>
            </IngredientsWrapper>
            <Footer>
                <h2>If you enjoyed this recipe, make sure to view more!</h2>
                <Link to="/recipes">
                    <Button color={Colors.green}>More Recipes</Button>
                </Link>
            </Footer>
        </RecipeContainer>
    )
}

export default Recipe
