import { Auth, Storage } from 'aws-amplify'

Storage.configure({ level: 'protected' })

export async function uploadImage(file: File, progress?: (prog: { loaded: number; total: number }) => void) {
    const session = await Auth.currentSession()
    console.log('Got credentials')
    const sub = session.getAccessToken().decodePayload().sub
    console.log({ sub, session })
    const path = `${file.name}`
    return Storage.put(path, file, { level: 'protected', progressCallback: progress })
}

export function deleteImage(key: string) {
    return Storage.remove(key)
}
