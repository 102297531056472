// https://stackoverflow.com/a/12646864/8965010
// ES6 version of the above - Mar 9, 2020 last edited
const shuffleArray = <Type>(array: Type[]) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
}
export { shuffleArray }
