import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import { CartItem, Item } from '../../data/item'
import NumberInput from '../NumberInput'
import { Colors } from '../../helpers/theme'
import cartLogo from '../../assets/supermarket.svg'

interface Props {
    items: CartItem[]
    itemChange: (item: Item, qty: number) => void
}

const ItemText = styled.p`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 1em;
`

const TableCell = styled.td`
    padding: 0.5rem 1rem;
    width: 1px;
`
const TableHeading = styled(TableCell)`
    width: 1px;
`

const Table = styled.div`
    background-color: ${Colors.blueGray};
    border-radius: 0.5rem;
    margin: 0.5rem;
`

function ListItem(item: CartItem, onChange: (num: number) => void) {
    return (
        <tr key={item.item.name}>
            <TableCell>{item.item.name}</TableCell>
            <TableCell>{<NumberInput onChange={(num) => onChange(num)} value={item.quantity} />}</TableCell>
            <TableCell>{item.quantity * item.item.cost}</TableCell>
        </tr>
    )
}

const CartContainer = styled.div`
    background-color: ${Colors.green};
    border-radius: 1em;
    border: solid ${Colors.ivory} 1px;
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    height: 30em;
    width: 25em;
    p {
        font-size: 1.5em;
    }
`

const CartMenuHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-top: 0.5em;
    span {
        margin-left: 1em;
        font-size: 2em;
    }
`
const CartIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 0.75em;
    width: 2em;
    height: 2em;
    img {
        width: 2.5em;
        height: 2.5em;
    }
`

const CartButton = styled.div`
    position: fixed;
    top: 2em;
    left: 2em;
    width: 4em;
    height: 4em;
    background-color: ${Colors.green};
    border-color: ${Colors.ivory};
    border-width: 1px;
    border-style: solid;
    border-radius: 0.75em;
    z-index: 98;

    .cart-container-enter {
        width: 0;
        height: 0;
        flex-flow: column;
        overflow: hidden;
        transition: all 0.5s ease;
    }

    .cart-container-enter-active {
        transition: all 0.5s ease;
        height: 30em;
        width: 25em;
    }

    .cart-container-exit {
        flex-flow: column;
        overflow: hidden;
        height: 30em;
        width: 25em;
    }

    .cart-container-exit-active {
        overflow: hidden;
        height: 0;
        width: 0;
        transition: all 0.5s ease;
    }
`

function Cart({ items, itemChange }: Props) {
    const [expanded, setExpanded] = useState(false)

    const node = useRef<HTMLDivElement>(null)

    const handleOutsideClick = (e: MouseEvent) => {
        if (node.current?.contains(e.target as Node)) {
            return
        } else {
            setExpanded(false)
        }
    }

    useEffect(() => {
        if (expanded) {
            document.addEventListener('mousedown', handleOutsideClick)
        } else {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [expanded])

    return (
        <CartButton onClick={() => setExpanded(!expanded)} ref={node}>
            <CartIcon>
                <img src={cartLogo} />
            </CartIcon>
            <CSSTransition
                in={expanded}
                timeout={500}
                classNames="cart-container"
                unmountOnExit
                onClick={(e: any) => e.stopPropagation()}
            >
                <CartContainer>
                    <CartMenuHeader>
                        <span>Cart</span>
                        <CartIcon style={{ marginRight: '2em', marginTop: 'auto' }}>
                            <img src={cartLogo} />
                        </CartIcon>
                    </CartMenuHeader>
                    {items.length ? (
                        <Table>
                            <tbody>
                                <tr>
                                    <TableHeading>Product</TableHeading>
                                    <TableHeading>Quantity</TableHeading>
                                    <TableHeading style={{ width: 'auto' }}>Cost</TableHeading>
                                </tr>
                                {items.map((item) => ListItem(item, (num) => itemChange(item.item, num)))}
                                <tr>
                                    <td>
                                        <strong>Total</strong>
                                    </td>
                                    <td />
                                    <td>{items.reduce((acc, item) => acc + item.quantity * item.item.cost, 0)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    ) : (
                        <ItemText>There are no items in your cart.</ItemText>
                    )}
                </CartContainer>
            </CSSTransition>
        </CartButton>
    )
}

export default Cart
