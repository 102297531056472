interface ColorScheme {
    regular: string
    light: string
    xLight: string
    dark: string
    xDark: string
}

// Color Scheme https://paletton.com/#uid=3001n0kleqtbzEKgVuIpcmGtdhZ

const primary: ColorScheme = {
    regular: '#ED3236',
    light: '#F57373',
    xLight: '#FFA3A3',
    dark: '#B52626',
    xDark: '#8F0D0D',
}

const secondary: ColorScheme = {
    regular: '#D3BD47',
    light: '#F5E173',
    xLight: '#FFF1A3',
    dark: '#B59F26',
    xDark: '#8F7B0D',
}

const accent: ColorScheme = {
    regular: '#4D3D91',
    light: '#6A5AA9',
    xLight: '#9589C8',
    dark: '#37267D',
    xDark: '#231463',
}

export const color = {
    primary,
    secondary,
    accent,
    textOnPrimary: '#efefef',
    muted: '',
}

/* Object */
export const Colors = {
    fairRed: '#ed3236',
    mango: '#f1864e',
    ivory: '#f6f7eb',
    green: '#4d8c57',
    blueGray: '#809bce',
}
