import React, { useEffect } from 'react'
import styled from 'styled-components'
import BoothForm from '../../molecules/vendor/BoothForm'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'

const Form = styled.div`
    padding: 1em;
`

function Create() {
    const history = useHistory()
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((session) => {
                console.log({ session })
            })
            .catch((err) => {
                console.error(err)
                console.log('User not signed in, redirecting to sign in')
                localStorage.setItem('signinRedirect', window.location.pathname)
                history.push('/signin')
            })
    }, [])
    return (
        <Form>
            <BoothForm />
        </Form>
    )
}

export default Create
