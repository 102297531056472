import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// import { getBooth, BoothData } from '../../data/mock'
import { generalGetBooth } from '../../custom-graphql/myQueries'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api/lib-esm'
import { BoothData } from '../../data/mock'
import { GetBoothQuery } from '../../API'
import BoothTemplate from '../../templates/Booth'
import Streams from '../../molecules/Streams'
import { Colors } from '../../helpers/theme'
import { shuffleArray } from '../../helpers/utils'
import { agricultureIds, businessIds, entertainerIds, nonProfitIds } from '../../data/boothIds'

const StreamHolder = styled.div`
    display: block;

    h3 {
        float: left;
        margin: 0.5em;
    }
`

const BoothContainer = styled.div`
    h1 {
        font-size: 2em;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    background-color: ${Colors.fairRed};
`
const BackgroundStyle = styled.div`
    background-color: ${Colors.ivory};
    border-radius: 1em;
    width: 95%;
    @media (min-width: 550px) {
        max-width: 700px;
    }
`

interface Props {
    id: string
}

function Booth({ id }: Props) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [booth, setBooth] = useState<BoothData | null>(null)
    useEffect(() => {
        setLoading(true)
        ;(async function () {
            try {
                const response = (await API.graphql({
                    query: generalGetBooth,
                    variables: { id },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY,
                })) as GraphQLResult<GetBoothQuery>
                if (response.errors) {
                    console.error({ errors: response.errors })
                    setLoading(false)
                    setError(true)
                    return
                }
                if (response.data && response.data.getBooth) {
                    const {
                        id,
                        owner,
                        category,
                        title,
                        description,
                        youtubeUrl,
                        images,
                        livestream,
                        facebookId,
                        instagramHandle,
                        website,
                        logo,
                    } = response.data.getBooth

                    setBooth({
                        id,
                        owner,
                        category,
                        title,
                        description,
                        youtubeUrl,
                        images,
                        livestream,
                        facebookId,
                        instagramHandle,
                        website,
                        logo,
                    })
                }
            } catch (err) {
                console.error({ err })
            }
            setLoading(false)
        })()
    }, [])
    if (error) return <h1>Error</h1>
    if (!booth || loading) return <h1>Loading ...</h1>
    return (
        <BoothContainer>
            <BackgroundStyle>
                <BoothTemplate boothData={booth} />

                <StreamHolder>
                    <h3>Discover more </h3>
                    <Streams
                        streamIds={shuffleArray([
                            ...businessIds,
                            ...agricultureIds,
                            ...entertainerIds,
                            ...nonProfitIds,
                        ])}
                    />
                </StreamHolder>

                {/* <Link style={{ marginTop: '1em' }} to={'/booth/next-steps'}>
                <Button color={Colors.fairRed} hover={Colors.mango}>
                    Next Steps
                </Button>
            </Link> */}
            </BackgroundStyle>
        </BoothContainer>
    )
}

export default Booth
