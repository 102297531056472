/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:4421eae3-60ff-4af2-9ad1-417fad2553fa',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_K0LNMdodk',
    aws_user_pools_web_client_id: '31vkqjbcit06b1r3hss5ps0l3a',
    oauth: {
        domain: 'myfair-prod.auth.us-west-2.amazoncognito.com',
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: 'https://myfair.ca/,https://dev.myfair.ca/,http://localhost:3000/',
        redirectSignOut: 'https://myfair.ca/,https://dev.myfair.ca/,https://localhost:3000/',
        responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
    aws_appsync_graphqlEndpoint: 'https://c23mbwltmrf7rdvkrmr6dd7icy.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-r45sf2nrynar7pgbp5fdowoguu',
    aws_user_files_s3_bucket: 'myfaircf7e3ec704c742538c28c528b5020384170126-prod',
    aws_user_files_s3_bucket_region: 'us-west-2',
}

export default awsmobile
