import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { API, Auth } from 'aws-amplify'
import { boothsByOwner } from '../custom-graphql/myQueries'
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api/lib-esm'
import { BoothsByOwnerQuery } from '../API'
import BoothTemplate from '../templates/Booth'
import { BoothData } from '../data/mock'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const BoothHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2em;
    border-bottom: 2px solid #e0e0e0;
`
const BButton = styled(Button)`
    margin: 1em;
    width: 10em;
`
const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

function MyBooths() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [ownerBooths, setOwnerBooths] = useState<BoothData[]>([])

    useEffect(() => {
        ;(async function () {
            setLoading(true)
            try {
                const session = await Auth.currentSession()
                const owner = session.getAccessToken().decodePayload().username
                const response = (await API.graphql({
                    query: boothsByOwner,
                    variables: {
                        owner,
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY,
                })) as GraphQLResult<BoothsByOwnerQuery>
                if (response.errors) {
                    setLoading(false)
                    setError(true)
                    console.error({ errors: response.errors })
                    return
                }
                if (response.data?.boothsByOwner?.items) {
                    response.data.boothsByOwner.items.forEach((item) => {
                        const {
                            id,
                            owner,
                            category,
                            logo,
                            title,
                            description,
                            images,
                            livestream,
                            youtubeUrl,
                            facebookId,
                            instagramHandle,
                            website,
                        } = item as BoothData

                        ownerBooths.push({
                            id,
                            owner,
                            category,
                            logo,
                            title,
                            description,
                            images,
                            livestream,
                            youtubeUrl,
                            facebookId,
                            instagramHandle,
                            website,
                        })
                        setOwnerBooths(ownerBooths)
                    })
                }
            } catch (err) {
                console.error({ err })
            }
            setLoading(false)
        })()
    }, [])

    if (error) return <h1>Error</h1>
    if (loading) return <h1>Loading ...</h1>
    if (ownerBooths.length == 0)
        return (
            <div>
                <h1>No booths here yet!</h1>
                <Link to={`/booth/create`} style={{ fontSize: '2em' }}>
                    Create my first Booth
                </Link>
            </div>
        )
    return (
        <div>
            {ownerBooths?.map((booth) => {
                return (
                    <BoothHolder key={booth.id}>
                        <BoothTemplate boothData={booth} />
                        <ButtonContainer>
                            <Link to={`/booth/${booth.id}`}>
                                <BButton>Visit Booth</BButton>
                            </Link>
                            <Link to={`/booth/${booth.id}/edit`}>
                                <BButton>Edit Booth</BButton>
                            </Link>
                        </ButtonContainer>
                    </BoothHolder>
                )
            })}
        </div>
    )
}

export default MyBooths
