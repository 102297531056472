import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

function MyRoutes() {
    const { url, path } = useRouteMatch()
    return (
        <Switch>
            <Route path={`${path}/Booths`}>
                <div>temp</div>
            </Route>
        </Switch>
    )
}

export default MyRoutes
