import React from 'react'
import Recipe from '../templates/Recipe'
import { Recipe as R } from '.'

const ArugulaPesto: R = {
    title: 'Arugula Pesto',
    farm: 'Arrowvale Farm',
    ingredients: [
        ' 2 cups packed arugula leaves, or a mixture of basil and arugula ',
        '1/2 cup toasted walnut pieces ',
        '6 cloves roast garlic ',
        '1/2 cup extra virgin olive oil or veg oil ',
        '1/2 cup grated parmesan cheese',
    ],
    steps: [
        'Peel garlic and put in a small pan with a drizzle of oil. Cook on low until soft and slightly brown.',
        'Toast the walnuts in the oven at 300 at the same time. Cool walnuts and garlic to room temperature before using.',
        'Put garlic, walnuts, parmesan and arugula in a food processor and blend to a paste. I squeeze in a bit of lemon juice or throw in an ice cube to keep the green colour. Slowly drizzle in oil, add salt to taste.',
        'This pesto freezes well.',
    ],
}

const BakedSquash: R = {
    title: 'Baked Acorn Squash',
    farm: '',
    ingredients: [
        ' 1 acorn squash, cut in ½',
        '2 tablespoons brown sugar',
        '2 tablespoons butter, softened',
        '2 tablespoons local maple syrup',
        'Salt',
        'Freshly ground black pepper',
    ],
    steps: [
        'Preheat oven to 400 degrees F. ',
        'Scoop the seeds and stringy pulp out of the squash cavities and discard. ',
        'In a small mixing bowl, combine the brown sugar, butter, syrup and salt and pepper, to taste.',
        'Rub the squash cavities and cut sides of the squash with the butter mixture and place them on a baking sheet, cut side up.',
        'Bake in the preheated oven for about 1 hour until the squash is tender when pierced with a fork.',
    ],
}

const GarlicScapeButter: R = {
    title: 'Garlic Scape Compund Butter',
    farm: 'Old Orchard Farm',
    ingredients: [
        '1 lb. unsalted butter softened to room temperature.',
        '1 cup garlic scapes',
        'Cracked black pepper',
        '2 tbsp chopped fresh parsley',
        '1 tbsp chopped fresh basil',
        'Optional: feel free to choose alternate or additional fresh herbs like tarragon, oregano, fennel and thyme',
        '1 splash Olive Oil',
    ],
    steps: [
        ' Prepare the scape by cutting off the long-pointed end above the flower bulb, and a bit of the lower stem if it seems tougher than the rest.',
        'Finely mince or chop the scapes by hand or use a food processor. Sauté them in the olive oil over medium low heat to bring out the bright green colour and flavour, and to soften them, then cool to room temperature.',
        'Whip butter until fluffy and light. Add minced fresh herbs, pepper and cooled scapes to the butter and whip until thoroughly blended.',
        'Spread the butter 1/2 an inch thick on a sheet of parchment paper, top with a second sheet of parchment and freeze.',
        'Store in a sealed bag or container in the freezer and simply snap off sections to use.',
    ],
}

const RoastedGarlic: R = {
    title: 'Roasted Garlic Cloves',
    farm: 'Old Orchard Farm',
    ingredients: ['garlic bulbs', 'olive oil', 'aluminum foil'],
    steps: [
        'Preheat oven (or BBQ) to 400 F. (This recipe is for the oven, but it can also be done on the barbeque with indirect heat).',
        'Prepare your garlic by removing most but not all of the outer layers of the bulb wrapping, you want the bulb to hold together.',
        ' Slice the top of the garlic bulb off (depending on the size of your bulb it should be around 1/4 to a 1/2 of an inch from the top) to expose the individual cloves.',
        'Drizzle 1 to 2 teaspoons of olive oil over the exposed surface of the garlic, letting the oil sink down into the cloves',
        'Wrap each head of garlic in aluminum foil and roast in the oven for 40 minutes.',

        'Check by inserting a knife into the top of the clove if the knife goes in as if into warm butter, its done. Depending on the size and age of the clove it may need more time, check every 10 minutes. You are looking for a dark golden color and a caramelized look and the cloves should be soft to touch.',
        'Let the garlic cool slightly, and then serve with baguette, toast, or crackers. To remove from its wrapping, press on the bottom of a clove to push it out of its paper, or use a knife to extract.',
        'Roasted garlic can also be refrigerated for up to 2 weeks or frozen for up to 3 months.',
    ],
}

const RoastedSalad: R = {
    title: 'Roasted Green Bean, Red Onion and Beet Salad',
    farm: '',
    ingredients: [
        '10 large beets, trimmed',
        '6 tablespoons extra-virgin olive oil',
        '8 teaspoons chopped fresh thyme',
        'Nonstick vegetable oil spray',
        '4 red onions, each cut into 6 wedges',
        '2.5lbs slender green beans, trimmed, cut into 3-inch lengths',
        '¼ Cup water',
        '3 tablespoons balsamic vinegar',
    ],
    steps: [
        'Preheat oven to 400F. Wrap beets tightly in foil. Place directly on oven rack. Roast until tender when pierced with knife, about 1 hour. Cool Beets. Peel and quarter beets. Transfer to large bowl. Add 2 tablespoons olive oil, 2 tsp. thyme, salt and pepper; toss to coat.',
        'Spray 2 large rimmed baking sheets with nonstick spray.',
        'Divide onion wedges between baking sheets and brush with 2 tablespoons oil and sprinkle with 4 teaspoons thyme, salt, and pepper. Arrange onions cut side down and roast until golden brown on bottom, about 10 minutes. Turn onions over and roast another 10 minutes until golden brown and tender. Transfer to another large bowl.',
        'Divide green beans between same baking sheets. Drizzle beans with remaining 2 tablespoons olive oil, ¼ cup water and 2 tsp. thyme, salt and pepper. Cover tightly with foil and roast until almost crisp-tender, about 14 minutes. Uncover and continue to roast until water evaporates and beans are crisp-tender, about 5 minutes. Transfer to bowl with onions. (Beets, onions, and green beans can be prepared 2 hours ahead. Let stand at room temperature.)',
        'Drizzle onions and green beans with balsamic vinegar; toss to coat. Season to taste with salt and pepper. Top with beets and serve warm or room temperature. Makes 10 servings.',
    ],
}

const SpaghettiSquash: R = {
    title: 'Spaghetti Squash with Chunky Tomatoe Sauce',
    farm: '',
    ingredients: [
        '1 medium spaghetti squash (3-4 pounds) or two small spaghetti squash',
        '1 tablespoon extra-virgin olive oil',
        'salt and pepper',
        '1 cup Parmesan or fresh ricotta cheese',
        '4 pounds fresh tomatoes',
        '1 Onion',
        '2 tablespoons extra-virgin olive oil',
        '2 cloves garlic',
        'salt and pepper',
    ],
    steps: [
        'Preheat the oven to 400 degrees. Cut the spaghetti squash in half lengthwise. Use a spoon to scoop out the seeds and discard them.',
        'Rub olive oil on the insides of the squash. Add salt and pepper to taste. Place the squash, cut side down, on a baking sheet.',
        'Core and roughly chop the tomatoes. Finely chop the garlic and onions. Toss the tomatoes, garlic, onion, and olive oil together in an oven-safe pot or Dutch oven that has a tight fitting lid. Put the tomatoes in the oven.',
        'After 45 minutes, start checking both the squash and the sauce to see if they are done. The squash is done when the insides can be poked with gently with a fork. The threads of the spaghetti squash should be slightly crunchy and watery, sometimes called &quot;cucumber-like.&quot; If the squash flesh becomes creamy, it is over-cooked. While over-cooked spaghetti squash will still taste fine, it will not separate into its signature spaghetti-like strands. The tomatoes will be done when they have softened and have started to form a sauce.',
        'Allow the squash to cool for five minutes. Then, using a fork, pull the strands of the squash out of the shell. Toss the squash with the oil and the cheese. Add salt and pepper to taste.',
    ],
}

export const VeggieRecipes = [
    ArugulaPesto,
    BakedSquash,
    GarlicScapeButter,
    RoastedGarlic,
    RoastedSalad,
    SpaghettiSquash,
]
export { ArugulaPesto, BakedSquash, GarlicScapeButter, RoastedGarlic, RoastedSalad, SpaghettiSquash }
