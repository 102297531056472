import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../helpers/theme'
import { Item, CartItem, protein, fruitVeg, grains, sweets } from '../../data/item'
import ItemGrid from '../../molecules/grocery/ItemGrid'
import { Form, Modal } from 'react-bootstrap'
import Cart from '../../molecules/grocery/Cart'
import Button from '../../molecules/Button'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api/lib-esm'
import { createGroceryEntry } from '../../graphql/mutations'
import { CreateGroceryEntryInput } from '../../API'
import { Link } from 'react-router-dom'

const ShopContainer = styled.div`
    background-color: ${Colors.ivory};
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

const Header = styled.div`
    background-color: ${Colors.fairRed};
    color: ${Colors.ivory};
    width: 100%;
    padding: 2em;
    h3 {
        font-size: 3em;
    }
    p {
        font-size: 1.5em;
        font-weight: 700;
    }
    span {
        font-size: 2em;
    }
`

const Footer = styled.div`
    width: 100%;
    padding: 2em;
    background-color: ${Colors.fairRed};
    color: ${Colors.ivory};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    label {
        font-size: 1.5em;
    }
`

function CompleteModal({ show }: { show: boolean }) {
    return (
        <Modal show={show} backdrop="static" keyboard={true}>
            <Modal.Header closeButton>
                <Modal.Title>Submitted!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Thanks for playing the grocery game! You have been entered for a chance to win $200 in amazing produce
                and products from our featured farms, ranging from a variety of water buffalo dairy products, flour and
                grains, garlic, jams and vegetables.
            </Modal.Body>
            <Modal.Footer>
                <Link to="/recipes">
                    <Button color={Colors.fairRed}>Go to Recipes!</Button>
                </Link>
                <Link to="/">Go to Homepage</Link>
            </Modal.Footer>
        </Modal>
    )
}

function emailValidation(email: string) {
    if (email.match(/^\S+@\S+\.\S{2,}$/)) return true
    return false
}

function phoneValidation(phone: string) {
    if (phone.match(/^(\d{3})(-?)(\d{3})-?(\d{4})$/)) return true
    return false
}

function nameValidation(name: string) {
    if (name.length > 0) return true
    return false
}

function getCartCost(items: CartItem[]) {
    return items.reduce((acc, item) => acc + item.quantity * item.item.cost, 0)
}

function Shop() {
    // const [items, setItems] = useState<Item[]>([])
    const [selection, setSelection] = useState<CartItem[]>([])
    const [filterText, setFilterText] = useState('')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [submitted, setSubmitted] = useState(false)

    const balance = 50
    const items = [...protein, ...fruitVeg, ...grains, ...sweets]

    function handleAddItem(item: Item, quantity: number) {
        const index = selection.findIndex((cartItem) => cartItem.item.name === item.name)
        if (quantity < 1) return
        if (index < 0) {
            setSelection([...selection, { item, quantity }])
        } else {
            const newSelection = [...selection]
            newSelection[index] = { ...newSelection[index], quantity: newSelection[index].quantity + quantity }
            setSelection(newSelection)
        }
    }

    function handleUpdateQuantity(item: Item, quantity: number) {
        const index = selection.findIndex((cartItem) => cartItem.item.name === item.name)
        if (index >= 0) {
            if (quantity >= 0) {
                const newSelection = selection.map((s) => ({ ...s })) // copy of CartItems, but not copy of CartItem.item
                newSelection[index].quantity = quantity
                if (getCartCost(newSelection) <= balance) {
                    console.log('balance greater than new cart cost')
                    setSelection(newSelection)
                }
            } else {
                // Hitting - button again causes item to be removed
                const newSelection = selection.filter((value, idx) => !(idx === index))
                setSelection(newSelection)
            }
        }
    }

    function validEntry() {
        if (!phoneValidation(phone)) return false
        if (!emailValidation(email)) return false
        if (!nameValidation(name)) return false
        if (Math.round(getCartCost(selection)) > 50) return false
        if (selection.length < 1) return false
        return true
    }

    async function handleSubmit() {
        try {
            console.log('Submitting')
            if (!validEntry()) return

            const submission = JSON.stringify(selection.map((val) => ({ item: val.item.name, quantity: val.quantity })))
            const groceryEntryInput: CreateGroceryEntryInput = {
                email,
                phone,
                name,
                submission,
            }
            const response = await API.graphql({
                query: createGroceryEntry,
                variables: {
                    input: groceryEntryInput,
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY,
            })
            setSubmitted(true)
            console.log(response)
        } catch (err) {
            console.error(err)
        }
    }

    const filterItems = items.filter((item) => item.name.toLowerCase().includes(filterText.toLowerCase()))

    const remainingBalance = balance - getCartCost(selection)

    return (
        <ShopContainer className="shop">
            <Header>
                <h1>The Great Grocery Event!</h1>
                <p>
                    The draw will end on September 13, 2021 at 11:59pm (Extended from Sept 12). Any entries received
                    after this date will not be drawn from, but feel free to create a grocery cart! Also, make sure to
                    check out the amazing farm fresh recipes!
                </p>
                <Link to="/recipes">
                    <Button color={Colors.green}>See Recipes</Button>
                </Link>
                <p>
                    The prize for participating in the grocery store event will consist of amazing produce and products
                    from our featured farms, ranging from a variety of water buffalo dairy products, flour and grains,
                    garlic, jams and vegetables. We will arrange getting your prize to you in upcycled feed bags.
                </p>
                <span>What can you buy with $50?</span>
            </Header>
            <h2>Remaining Balance: {remainingBalance}</h2>
            <Cart items={selection} itemChange={handleUpdateQuantity} />
            <Form>
                <Form.Group controlId="search">
                    <Form.Control
                        type="search"
                        placeholder="Enter search"
                        value={filterText}
                        onChange={(elem) => setFilterText(elem.target.value)}
                    />
                </Form.Group>
            </Form>
            <ItemGrid items={filterItems} addItem={handleAddItem} remainingBalance={remainingBalance}></ItemGrid>
            <Footer>
                <Form style={{ maxWidth: '20em' }}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            isValid={nameValidation(name)}
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            isValid={emailValidation(email)}
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            placeholder="250-731-1234"
                            type="text"
                            value={phone}
                            isValid={phoneValidation(phone)}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Text style={{ fontSize: '1em', marginBottom: '0.5em' }}>
                        We will only use this information to contact you if you win.
                    </Form.Text>
                    <Button color={Colors.green} disabled={!validEntry()} onClick={() => handleSubmit()}>
                        Submit
                    </Button>
                </Form>
                <div style={{ marginTop: '2em' }}>
                    Cart icon made by{' '}
                    <a href="https://www.flaticon.com/authors/icongeek26" title="Icongeek26">
                        Icongeek26
                    </a>{' '}
                    from{' '}
                    <a href="https://www.flaticon.com/" title="Flaticon">
                        www.flaticon.com
                    </a>
                </div>
            </Footer>
            <CompleteModal show={submitted} />
        </ShopContainer>
    )
}

export default Shop
