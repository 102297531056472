import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import config from './aws-exports'

const updateConfig = { ...config }
updateConfig.oauth.domain = 'auth.myfair.ca'
updateConfig.oauth.redirectSignIn = updateConfig.oauth.redirectSignOut = 'https://myfair.ca/'
/* eslint-disable */
// ;(updateConfig.oauth as any).redirect_uri = 'http://localhost:3000/'
/* eslint-enable */

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
    () => Amplify.configure(updateConfig),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
