import React from 'react'
import Form from 'react-bootstrap/Form'
import { FormControlProps } from 'react-bootstrap/FormControl'

interface FormGroupProps extends React.PropsWithChildren<{}> {
    id: string
    label: string
    placeholder?: string
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    type: FormControlProps['type']
    value: FormControlProps['value'] | boolean
    error?: string
    touched?: boolean
}

function FormGroup({
    id,
    label,
    placeholder,
    onBlur,
    onChange,
    type,
    value,
    error,
    touched,
    children,
}: FormGroupProps) {
    const key = type === 'select' ? 'as' : 'type'
    const addedProp = { [key]: type }

    return (
        <Form.Group controlId={id}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                {...addedProp}
                checked={typeof value === 'boolean' ? value : undefined}
                value={typeof value !== 'boolean' ? value : undefined}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={placeholder}
                isInvalid={!!error && !!touched}
                name={id}
            >
                {children}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
    )
}

export default FormGroup
