/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateRegisterInput = {
  owner: string,
  type: Category,
  altEmail?: string | null,
  businessName?: string | null,
};

export enum Category {
  BUSINESS = "BUSINESS",
  ENTERTAINER = "ENTERTAINER",
  COMPETITOR = "COMPETITOR",
  ATTENDEE = "ATTENDEE",
  AGRICULTURE = "AGRICULTURE",
}


export type ModelRegisterConditionInput = {
  type?: ModelCategoryInput | null,
  altEmail?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  and?: Array< ModelRegisterConditionInput | null > | null,
  or?: Array< ModelRegisterConditionInput | null > | null,
  not?: ModelRegisterConditionInput | null,
};

export type ModelCategoryInput = {
  eq?: Category | null,
  ne?: Category | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type UpdateRegisterInput = {
  owner: string,
  type?: Category | null,
  altEmail?: string | null,
  businessName?: string | null,
};

export type DeleteRegisterInput = {
  owner: string,
};

export type UpdateEmailInput = {
  id: string,
};

export type ModelEmailConditionInput = {
  and?: Array< ModelEmailConditionInput | null > | null,
  or?: Array< ModelEmailConditionInput | null > | null,
  not?: ModelEmailConditionInput | null,
};

export type UpdateGroceryEntryInput = {
  submission?: string | null,
  email: string,
  phone?: string | null,
  name?: string | null,
};

export type ModelGroceryEntryConditionInput = {
  submission?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelGroceryEntryConditionInput | null > | null,
  or?: Array< ModelGroceryEntryConditionInput | null > | null,
  not?: ModelGroceryEntryConditionInput | null,
};

export type DeleteGroceryEntryInput = {
  email: string,
};

export type CreateBoothInput = {
  id?: string | null,
  owner?: string | null,
  category?: Category | null,
  email?: string | null,
  reviewed?: boolean | null,
  title: string,
  description: string,
  livestream?: string | null,
  logo?: string | null,
  images: Array< string >,
  youtubeUrl?: string | null,
  instagramHandle?: string | null,
  facebookId?: string | null,
  website?: string | null,
};

export type ModelBoothConditionInput = {
  category?: ModelCategoryInput | null,
  email?: ModelStringInput | null,
  reviewed?: ModelBooleanInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  livestream?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  images?: ModelStringInput | null,
  youtubeUrl?: ModelStringInput | null,
  instagramHandle?: ModelStringInput | null,
  facebookId?: ModelStringInput | null,
  website?: ModelStringInput | null,
  and?: Array< ModelBoothConditionInput | null > | null,
  or?: Array< ModelBoothConditionInput | null > | null,
  not?: ModelBoothConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateBoothInput = {
  id: string,
  owner?: string | null,
  category?: Category | null,
  email?: string | null,
  reviewed?: boolean | null,
  title?: string | null,
  description?: string | null,
  livestream?: string | null,
  logo?: string | null,
  images?: Array< string > | null,
  youtubeUrl?: string | null,
  instagramHandle?: string | null,
  facebookId?: string | null,
  website?: string | null,
};

export type DeleteBoothInput = {
  id?: string | null,
};

export type CreateEmailInput = {
  id: string,
};

export type DeleteEmailInput = {
  id?: string | null,
};

export type CreateGroceryEntryInput = {
  submission: string,
  email: string,
  phone: string,
  name: string,
};

export type ModelRegisterFilterInput = {
  owner?: ModelStringInput | null,
  type?: ModelCategoryInput | null,
  altEmail?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  and?: Array< ModelRegisterFilterInput | null > | null,
  or?: Array< ModelRegisterFilterInput | null > | null,
  not?: ModelRegisterFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelEmailFilterInput = {
  id?: ModelStringInput | null,
  and?: Array< ModelEmailFilterInput | null > | null,
  or?: Array< ModelEmailFilterInput | null > | null,
  not?: ModelEmailFilterInput | null,
};

export type ModelGroceryEntryFilterInput = {
  submission?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelGroceryEntryFilterInput | null > | null,
  or?: Array< ModelGroceryEntryFilterInput | null > | null,
  not?: ModelGroceryEntryFilterInput | null,
};

export type ModelBoothFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  category?: ModelCategoryInput | null,
  email?: ModelStringInput | null,
  reviewed?: ModelBooleanInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  livestream?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  images?: ModelStringInput | null,
  youtubeUrl?: ModelStringInput | null,
  instagramHandle?: ModelStringInput | null,
  facebookId?: ModelStringInput | null,
  website?: ModelStringInput | null,
  and?: Array< ModelBoothFilterInput | null > | null,
  or?: Array< ModelBoothFilterInput | null > | null,
  not?: ModelBoothFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type CreateRegisterMutationVariables = {
  input: CreateRegisterInput,
  condition?: ModelRegisterConditionInput | null,
};

export type CreateRegisterMutation = {
  createRegister:  {
    __typename: "Register",
    owner: string,
    type: Category,
    altEmail: string | null,
    businessName: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRegisterMutationVariables = {
  input: UpdateRegisterInput,
  condition?: ModelRegisterConditionInput | null,
};

export type UpdateRegisterMutation = {
  updateRegister:  {
    __typename: "Register",
    owner: string,
    type: Category,
    altEmail: string | null,
    businessName: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRegisterMutationVariables = {
  input: DeleteRegisterInput,
  condition?: ModelRegisterConditionInput | null,
};

export type DeleteRegisterMutation = {
  deleteRegister:  {
    __typename: "Register",
    owner: string,
    type: Category,
    altEmail: string | null,
    businessName: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEmailMutationVariables = {
  input: UpdateEmailInput,
  condition?: ModelEmailConditionInput | null,
};

export type UpdateEmailMutation = {
  updateEmail:  {
    __typename: "Email",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGroceryEntryMutationVariables = {
  input: UpdateGroceryEntryInput,
  condition?: ModelGroceryEntryConditionInput | null,
};

export type UpdateGroceryEntryMutation = {
  updateGroceryEntry:  {
    __typename: "GroceryEntry",
    submission: string,
    email: string,
    phone: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGroceryEntryMutationVariables = {
  input: DeleteGroceryEntryInput,
  condition?: ModelGroceryEntryConditionInput | null,
};

export type DeleteGroceryEntryMutation = {
  deleteGroceryEntry:  {
    __typename: "GroceryEntry",
    submission: string,
    email: string,
    phone: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBoothMutationVariables = {
  input: CreateBoothInput,
  condition?: ModelBoothConditionInput | null,
};

export type CreateBoothMutation = {
  createBooth:  {
    __typename: "Booth",
    id: string,
    owner: string | null,
    category: Category | null,
    email: string | null,
    reviewed: boolean | null,
    title: string,
    description: string,
    livestream: string | null,
    logo: string | null,
    images: Array< string >,
    youtubeUrl: string | null,
    instagramHandle: string | null,
    facebookId: string | null,
    website: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBoothMutationVariables = {
  input: UpdateBoothInput,
  condition?: ModelBoothConditionInput | null,
};

export type UpdateBoothMutation = {
  updateBooth:  {
    __typename: "Booth",
    id: string,
    owner: string | null,
    category: Category | null,
    email: string | null,
    reviewed: boolean | null,
    title: string,
    description: string,
    livestream: string | null,
    logo: string | null,
    images: Array< string >,
    youtubeUrl: string | null,
    instagramHandle: string | null,
    facebookId: string | null,
    website: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBoothMutationVariables = {
  input: DeleteBoothInput,
  condition?: ModelBoothConditionInput | null,
};

export type DeleteBoothMutation = {
  deleteBooth:  {
    __typename: "Booth",
    id: string,
    owner: string | null,
    category: Category | null,
    email: string | null,
    reviewed: boolean | null,
    title: string,
    description: string,
    livestream: string | null,
    logo: string | null,
    images: Array< string >,
    youtubeUrl: string | null,
    instagramHandle: string | null,
    facebookId: string | null,
    website: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEmailMutationVariables = {
  input: CreateEmailInput,
  condition?: ModelEmailConditionInput | null,
};

export type CreateEmailMutation = {
  createEmail:  {
    __typename: "Email",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEmailMutationVariables = {
  input: DeleteEmailInput,
  condition?: ModelEmailConditionInput | null,
};

export type DeleteEmailMutation = {
  deleteEmail:  {
    __typename: "Email",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGroceryEntryMutationVariables = {
  input: CreateGroceryEntryInput,
  condition?: ModelGroceryEntryConditionInput | null,
};

export type CreateGroceryEntryMutation = {
  createGroceryEntry:  {
    __typename: "GroceryEntry",
    submission: string,
    email: string,
    phone: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetRegisterQueryVariables = {
  owner: string,
};

export type GetRegisterQuery = {
  getRegister:  {
    __typename: "Register",
    owner: string,
    type: Category,
    altEmail: string | null,
    businessName: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRegistersQueryVariables = {
  owner?: string | null,
  filter?: ModelRegisterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRegistersQuery = {
  listRegisters:  {
    __typename: "ModelRegisterConnection",
    items:  Array< {
      __typename: "Register",
      owner: string,
      type: Category,
      altEmail: string | null,
      businessName: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetEmailQueryVariables = {
  id: string,
};

export type GetEmailQuery = {
  getEmail:  {
    __typename: "Email",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEmailsQueryVariables = {
  filter?: ModelEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmailsQuery = {
  listEmails:  {
    __typename: "ModelEmailConnection",
    items:  Array< {
      __typename: "Email",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetGroceryEntryQueryVariables = {
  email: string,
};

export type GetGroceryEntryQuery = {
  getGroceryEntry:  {
    __typename: "GroceryEntry",
    submission: string,
    email: string,
    phone: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGroceryEntrysQueryVariables = {
  email?: string | null,
  filter?: ModelGroceryEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGroceryEntrysQuery = {
  listGroceryEntrys:  {
    __typename: "ModelGroceryEntryConnection",
    items:  Array< {
      __typename: "GroceryEntry",
      submission: string,
      email: string,
      phone: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetBoothQueryVariables = {
  id: string,
};

export type GetBoothQuery = {
  getBooth:  {
    __typename: "Booth",
    id: string,
    owner: string | null,
    category: Category | null,
    email: string | null,
    reviewed: boolean | null,
    title: string,
    description: string,
    livestream: string | null,
    logo: string | null,
    images: Array< string >,
    youtubeUrl: string | null,
    instagramHandle: string | null,
    facebookId: string | null,
    website: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBoothsQueryVariables = {
  filter?: ModelBoothFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBoothsQuery = {
  listBooths:  {
    __typename: "ModelBoothConnection",
    items:  Array< {
      __typename: "Booth",
      id: string,
      owner: string | null,
      category: Category | null,
      email: string | null,
      reviewed: boolean | null,
      title: string,
      description: string,
      livestream: string | null,
      logo: string | null,
      images: Array< string >,
      youtubeUrl: string | null,
      instagramHandle: string | null,
      facebookId: string | null,
      website: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type BoothsByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBoothFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BoothsByOwnerQuery = {
  boothsByOwner:  {
    __typename: "ModelBoothConnection",
    items:  Array< {
      __typename: "Booth",
      id: string,
      owner: string | null,
      category: Category | null,
      email: string | null,
      reviewed: boolean | null,
      title: string,
      description: string,
      livestream: string | null,
      logo: string | null,
      images: Array< string >,
      youtubeUrl: string | null,
      instagramHandle: string | null,
      facebookId: string | null,
      website: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type BoothsByCategoryQueryVariables = {
  category?: Category | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBoothFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BoothsByCategoryQuery = {
  boothsByCategory:  {
    __typename: "ModelBoothConnection",
    items:  Array< {
      __typename: "Booth",
      id: string,
      owner: string | null,
      category: Category | null,
      email: string | null,
      reviewed: boolean | null,
      title: string,
      description: string,
      livestream: string | null,
      logo: string | null,
      images: Array< string >,
      youtubeUrl: string | null,
      instagramHandle: string | null,
      facebookId: string | null,
      website: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type OnCreateRegisterSubscription = {
  onCreateRegister:  {
    __typename: "Register",
    owner: string,
    type: Category,
    altEmail: string | null,
    businessName: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRegisterSubscription = {
  onUpdateRegister:  {
    __typename: "Register",
    owner: string,
    type: Category,
    altEmail: string | null,
    businessName: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRegisterSubscription = {
  onDeleteRegister:  {
    __typename: "Register",
    owner: string,
    type: Category,
    altEmail: string | null,
    businessName: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEmailSubscription = {
  onCreateEmail:  {
    __typename: "Email",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEmailSubscription = {
  onUpdateEmail:  {
    __typename: "Email",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEmailSubscription = {
  onDeleteEmail:  {
    __typename: "Email",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGroceryEntrySubscription = {
  onCreateGroceryEntry:  {
    __typename: "GroceryEntry",
    submission: string,
    email: string,
    phone: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGroceryEntrySubscription = {
  onUpdateGroceryEntry:  {
    __typename: "GroceryEntry",
    submission: string,
    email: string,
    phone: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGroceryEntrySubscription = {
  onDeleteGroceryEntry:  {
    __typename: "GroceryEntry",
    submission: string,
    email: string,
    phone: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
