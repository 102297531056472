import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { Form, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import FormGroup from '../FormGroup'
import { useHistory } from 'react-router-dom'

const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/

const validationSchema = yup.object().shape({
    firstName: yup.string().label('First Name').required(),
    lastName: yup.string().label('Last Name').required(),
    email: yup.string().label('Email').email().required(),
    password: yup
        .string()
        .label('Password')
        .required()
        .min(8, 'Password is too short')
        .matches(passRegex, 'Password must contain at least 1 lowercase letter, uppercase letter and number.'),
    confirmPassword: yup
        .string()
        .required('Passwords must match.')
        .label('Confirm Password')
        .test('passwords-match', 'Passwords must match.', function (value) {
            return this.parent.password === value
        }),
})

const Message = styled.div`
    padding-top: 1em;
`

function SignUp() {
    const [signedUp, setSignedUp] = useState(false)

    const history = useHistory()

    return (
        <div>
            <Formik
                initialValues={{ firstName: '', lastName: '', email: '', password: '', confirmPassword: '' }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    try {
                        setSubmitting(true)
                        console.log('SignUp Handler')
                        console.log({ ...values, password: 'omitted', confirmPassword: 'omitted' })
                        const result = await Auth.signUp({
                            username: values.email.toLowerCase(),
                            password: values.password,
                            attributes: {
                                email: values.email.toLowerCase(),
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                given_name: `${values.firstName}`,
                                // eslint-disable-next-line @typescript-eslint/camelcase
                                family_name: `${values.lastName}`,
                            },
                        })
                        console.log('Sign up success!')
                        console.log(result)
                        setSignedUp(true)
                        // Show success
                    } catch (err) {
                        console.error('Sign Up Error.')
                        console.error(err)
                    } finally {
                        setSubmitting(false)
                    }
                }}
            >
                {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, isSubmitting }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <FormGroup
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            error={errors.email}
                            touched={touched.email}
                            id="email"
                            label="Email"
                            placeholder="me@example.com"
                            type="text"
                        />
                        <FormGroup
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                            error={errors.firstName}
                            touched={touched.firstName}
                            id="firstName"
                            label="First Name"
                            type="text"
                        />
                        <FormGroup
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                            error={errors.lastName}
                            touched={touched.lastName}
                            id="lastName"
                            label="Last Name"
                            type="text"
                        />
                        <FormGroup
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            error={errors.password}
                            touched={touched.password}
                            id="password"
                            label="Password"
                            type="password"
                        />
                        <FormGroup
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.confirmPassword}
                            error={errors.confirmPassword}
                            touched={touched.confirmPassword}
                            id="confirmPassword"
                            label="Confirm Password"
                            type="password"
                        />
                        <Button variant="primary" type="submit" disabled={isSubmitting || !isValid || signedUp}>
                            Sign Up
                        </Button>
                    </Form>
                )}
            </Formik>
            {signedUp && (
                <Message>
                    Successfuly signed up! Check your email for a confirmation link.{' '}
                    <Button style={{ paddingTop: '1em' }} onClick={() => history.push('/signin')}>
                        Sign In
                    </Button>
                </Message>
            )}
        </div>
    )
}

export default SignUp
