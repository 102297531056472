import React from 'react'
import styled from 'styled-components'
import CombinedLogo from '../../molecules/CombinedLogo'
import { Button } from 'react-bootstrap'

const Outer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;

    h3 {
        padding-top: 1.5em;
    }
    @media (min-width: 850px) {
        width: 800px;
    }
`

const Categories = styled.div`
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    width: 100%;
    height: 12em;
    justify-content: left;
    align-items: center;
    font-size: 1.5em;
`

const CategoryItem = styled.div`
    margin: 0.5em;
    width: 6em;
`

const Text = styled.div`
    font-size: 1.3em;
    padding-top: 1em;
`

export default function LandingCompetition() {
    return (
        <Outer>
            <CombinedLogo />
            <h1 style={{ marginTop: '1em' }}>Coming Soon!</h1>
        </Outer>
    )
}
