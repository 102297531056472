export const generalGetBooth = /* GraphQL */ `
    query GetBooth($id: ID!) {
        getBooth(id: $id) {
            id
            owner
            category
            title
            description
            livestream
            images
            youtubeUrl
            instagramHandle
            website
            facebookId
            logo
        }
    }
`

export const getBoothAdmin = /* GraphQL */ `
    query GetBooth($id: ID!) {
        getBooth(id: $id) {
            id
            owner
            category
            email
            reviewed
            title
            description
            livestream
            logo
            images
            youtubeUrl
            instagramHandle
            website
            createdAt
            updatedAt
        }
    }
`

export const generalCreateBooth = /* GraphQL */ `
    mutation CreateBooth($input: CreateBoothInput!, $condition: ModelBoothConditionInput) {
        createBooth(input: $input, condition: $condition) {
            id
            owner
            category
            title
            description
            livestream
            images
            youtubeUrl
            instagramHandle
            website
        }
    }
`

export const createBoothAdmin = /* GraphQL */ `
    mutation CreateBooth($input: CreateBoothInput!, $condition: ModelBoothConditionInput) {
        createBooth(input: $input, condition: $condition) {
            id
            owner
            category
            email
            reviewed
            title
            description
            livestream
            logo
            images
            youtubeUrl
            instagramHandle
            website
            createdAt
            updatedAt
        }
    }
`
export const generalUpdateBooth = /* GraphQL */ `
    mutation UpdateBooth($input: UpdateBoothInput!, $condition: ModelBoothConditionInput) {
        updateBooth(input: $input, condition: $condition) {
            id
            owner
            category
            title
            description
            livestream
            logo
            images
            youtubeUrl
            instagramHandle
            facebookId
            website
            createdAt
            updatedAt
        }
    }
`

export const boothsByOwner = /* GraphQL */ `
    query BoothsByOwner(
        $owner: String
        $sortDirection: ModelSortDirection
        $filter: ModelBoothFilterInput
        $limit: Int
        $nextToken: String
    ) {
        boothsByOwner(
            owner: $owner
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                category
                title
                description
                livestream
                logo
                images
                youtubeUrl
                instagramHandle
                facebookId
                website
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`

export const boothsByCategory = /* GraphQL */ `
    query BoothsByCategory(
        $category: Category
        $sortDirection: ModelSortDirection
        $filter: ModelBoothFilterInput
        $limit: Int
        $nextToken: String
    ) {
        boothsByCategory(
            category: $category
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                category
                title
                description
                livestream
                logo
                images
                youtubeUrl
                instagramHandle
                facebookId
                website
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`
