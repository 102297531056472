import Rabbit from '../assets/grocery/Rabbit.png'
import Beef from '../assets/grocery/Beef.jpg'
import Egg from '../assets/grocery/Egg.jpg'
import Cheese from '../assets/grocery/Cheese.jpg'
import Salmon from '../assets/grocery/Salmon.jpg'
import Oyster from '../assets/grocery/Oyster.jpg'
import Carrot from '../assets/grocery/Carrot.jpg'
import Apple from '../assets/grocery/Apple.jpg'
import Garlic from '../assets/grocery/Garlic.jpg'
import Kale from '../assets/grocery/Kale.jpg'
import Squash from '../assets/grocery/Squash.jpg'
import MapleSyrup from '../assets/grocery/MapleSyrup.jpg'
import Honey from '../assets/grocery/Honey.jpg'
import Oatmeal from '../assets/grocery/Oatmeal.jpg'
import Flour from '../assets/grocery/Flour.jpg'

export interface Item {
    cost: number
    name: string
    altName?: string
    description: string
    funFact: string
    whereToBuy: FarmInfo[]
    imgPath: string
}

export interface CartItem {
    item: Item
    quantity: number
}

interface FarmInfo {
    name: string
    website: string
}

const EdenTree: FarmInfo = {
    name: 'Edentree Farm and Gardens',
    website: 'http://www.edentreefarm.ca/edentreefarm/home.html',
}

const OneTree: FarmInfo = {
    name: 'One Tree Farm',
    website: '',
}

const NoRhymer: FarmInfo = {
    name: 'No Rhymer Reason Farm',
    website: '',
}

const Stonehaven: FarmInfo = {
    name: 'Stoneheaven Farm',
    website: '',
}

const Leda: FarmInfo = {
    name: 'Leda Farm',
    website: '',
}

const BurdeStreet: FarmInfo = {
    name: 'Burde Street Community Orchard',
    website: '',
}

const MadRaven: FarmInfo = {
    name: 'Mad Raven Farm',
    website: '',
}

const LettuceBe: FarmInfo = {
    name: 'Lettuce Be Microgreens',
    website: '',
}

const CrackinYolks: FarmInfo = {
    name: "Crackin' Yolks Farm",
    website: '',
}

const Coleman: FarmInfo = {
    name: 'Coleman Meadows Farm',
    website: '',
}

const Oosterom: FarmInfo = {
    name: 'Oosterom Farm',
    website: '',
}

const NewAcerage: FarmInfo = {
    name: 'New Acerage',
    website: '',
}

const Dols: FarmInfo = {
    name: "Dol's Farm",
    website: '',
}

const Darbys: FarmInfo = {
    name: "Darby's Farm",
    website: '',
}

const ToothAcres: FarmInfo = {
    name: 'Tooth Acres',
    website: '',
}

const Brook: FarmInfo = {
    name: 'Brook Farm',
    website: '',
}

const Cottonwood: FarmInfo = {
    name: 'Cottonwood Farms',
    website: '',
}

const Hector: FarmInfo = {
    name: 'The Farm on Hector Road',
    website: '',
}

const OakTree: FarmInfo = {
    name: 'Oak Tree Farm',
    website: '',
}

const Lee: FarmInfo = {
    name: 'The Lee Farm',
    website: '',
}

const Bobs: FarmInfo = {
    name: "Bob's Place",
    website: '',
}

const Potters: FarmInfo = {
    name: "Potter's Produce",
    website: '',
}

const McCoy: FarmInfo = {
    name: 'McCoy Lake Farm',
    website: '',
}

const Beardale: FarmInfo = {
    name: 'Beardale Farm',
    website: '',
}

const OldOrchard: FarmInfo = {
    name: 'Old Orchard Farm',
    website: '',
}

const LonelyPine: FarmInfo = {
    name: 'Lonely Pine Ranch',
    website: '',
}

const Naesgards: FarmInfo = {
    name: 'Naesgards',
    website: '',
}

const Shelter: FarmInfo = {
    name: 'Shelter Farm',
    website: '',
}

const VanIsle: FarmInfo = {
    name: 'Vancouver Island Grain and Milling',
    website: '',
}

const Hupacasath: FarmInfo = {
    name: 'Hupacasath Community Farm',
    website: '',
}

const Effingham: FarmInfo = {
    name: 'Effingham Oysters',
    website: '',
}

export const protein: Item[] = [
    {
        cost: 6,
        name: 'Rabbit',
        altName: 't ̓ut̓upč̓as',
        description: '1Lb. Lean meat, excellent source of protein and iron.',
        funFact: 'Environmentally friendly: Only 3 months from conception to harvest',
        imgPath: Rabbit,
        whereToBuy: [EdenTree, Cottonwood],
    },
    {
        cost: 6.5,
        name: 'Beef',
        altName: 'c ̓isqmis (means meat)',
        description: '1Lb Hamburger Meat. A great source of iron, important in healthy brain, muscle function.',
        funFact:
            'One way to tell if beef has been grass fed is by the colour of the fat.  It is yellow rather than white as grass fed beef is higher in carotenoids which discolours the fat.',
        imgPath: Beef,
        whereToBuy: [Stonehaven, Oosterom, ToothAcres, Brook, Lee, Bobs],
    },
    {
        cost: 5,
        name: 'Egg',
        altName: 'nuč̓ak',
        description: '1 Dozen. Eggs are one of the only foods that naturally contain vitamin D.',
        funFact: 'Did you know? Different types of chickens produce different coloured eggs.',
        imgPath: Egg,
        whereToBuy: [
            EdenTree,
            OneTree,
            NoRhymer,
            Leda,
            BurdeStreet,
            MadRaven,
            LettuceBe,
            CrackinYolks,
            NewAcerage,
            Dols,
            Darbys,
            ToothAcres,
            Brook,
            Hector,
            OakTree,
            Lee,
            Bobs,
            Potters,
        ],
    },
    {
        cost: 10,
        name: 'Cheese',
        description: '1 Tub. Cheese — and dairy products in general — work to protect your teeth from cavities.',
        funFact: 'Commercial milk can be harvested from cows, water buffalo, goats, and even sheep!',
        imgPath: Cheese,
        whereToBuy: [Coleman],
    },
    {
        cost: 20,
        name: 'Salmon',
        altName: 'saamin (general)',
        description: '1 Fish. Food for your brain!',
        funFact:
            'There are 5 species of salmon indigenous to the coastal waters of British Columbia; chinook, coho, sockeye, pink and chum salmon.',
        imgPath: Salmon,
        whereToBuy: [],
    },
    {
        cost: 7.5,
        name: 'Oyster',
        altName: 'ƛ̓uḥƛ̓uḥ',
        description:
            '1 Dozen 3-4" (raw size). Oysters are especially high in vitamin B12, zinc, selenium, vitamin D, and iron.',
        funFact:
            'Oysters have been eaten by humans since prehistoric times and cultivated since the Roman Empire at least',
        imgPath: Oyster,
        whereToBuy: [Effingham],
    },
]

export const fruitVeg: Item[] = [
    {
        cost: 3,
        name: 'Carrot',
        altName: 'ƛ̓iƛ̓iiḥan̓uuḥ',
        description: '1 Lb. Vitamin A in carrots protects eye health.',
        funFact:
            'Carrots were first cultivated in Afghanistan around 900 BC. You can leave carrots in the ground all winter long and harvest.',
        imgPath: Carrot,
        whereToBuy: [
            EdenTree,
            OneTree,
            NoRhymer,
            Leda,
            BurdeStreet,
            MadRaven,
            LettuceBe,
            CrackinYolks,
            OldOrchard,
            LonelyPine,
            OakTree,
            Lee,
            Potters,
            McCoy,
            Naesgards,
            Shelter,
        ],
    },
    {
        cost: 5,
        name: 'Apple',
        altName: 'ʕeepinis',
        description: '3 Lbs. May help reduce the risk of cancer, hypertension, diabetes, and heart disease.',
        funFact: 'Harvested fresh in the Valley from July through November',
        imgPath: Apple,
        whereToBuy: [EdenTree, Leda, BurdeStreet, LonelyPine, Naesgards],
    },
    {
        cost: 3,
        name: 'Kale',
        altName: 'ƛay̓iipt (means leaf)',
        description: '1/2 Lb. A cup of raw kale contains even more vitamin C than a whole orange!',
        funFact: 'Aphids got your garden’s kale? Just hose them off with water.',
        imgPath: Kale,
        whereToBuy: [
            EdenTree,
            OneTree,
            NoRhymer,
            Leda,
            BurdeStreet,
            MadRaven,
            LettuceBe,
            CrackinYolks,
            OldOrchard,
            LonelyPine,
            OakTree,
            Lee,
            Potters,
            McCoy,
            Naesgards,
            Shelter,
        ],
    },
    {
        cost: 2.5,
        name: 'Squash',
        description:
            '1 Lb. High in vitamins A, B6, and C, folate, magnesium, fiber, riboflavin, phosphorus, and potassium.',
        funFact: 'Squash is harvested in summer and fall and can be stored at home for up to a full year.',
        imgPath: Squash,
        whereToBuy: [
            EdenTree,
            OneTree,
            NoRhymer,
            Leda,
            BurdeStreet,
            MadRaven,
            LettuceBe,
            CrackinYolks,
            OldOrchard,
            LonelyPine,
            OakTree,
            Lee,
            Potters,
            McCoy,
            Naesgards,
            Shelter,
        ],
    },
    {
        cost: 3,
        name: 'Garlic',
        description:
            '1 head. Garlic’s sulphur-containing compound allicin gives its pungent smell and may have anti-cancer properties.',
        funFact: 'Garlic is planted in fall, usually  between late September and October.',
        imgPath: Garlic,
        whereToBuy: [
            EdenTree,
            Leda,
            MadRaven,
            LettuceBe,
            CrackinYolks,
            OldOrchard,
            LonelyPine,
            OakTree,
            Lee,
            Potters,
            McCoy,
            Naesgards,
            Shelter,
            Darbys,
        ],
    },
]

export const sweets: Item[] = [
    {
        cost: 20,
        name: 'Maple Syrup',
        description: '250ml. Just a 1/4 cup serving of maple syrup provides 41% of your daily requirement of zinc.',
        funFact: 'Canada’s West Coast Bigleaf Maple is a different variety from what is tapped on its East Coast.',
        imgPath: MapleSyrup,
        whereToBuy: [EdenTree, NoRhymer, Stonehaven, Leda, Hupacasath],
    },
    {
        cost: 12,
        name: 'Honey',
        description:
            '1Lb. Honey is known to have antioxidant, antimicrobial, and soothing effects.\n*Infants under a year old should not consume honey.',
        funFact: 'Honey bees will tap about two million flowers and fly 80,000 km to make one pound of honey.',
        imgPath: Honey,
        whereToBuy: [EdenTree, OneTree, Stonehaven],
    },
]

export const grains: Item[] = [
    {
        cost: 8.5,
        name: 'flour',
        description:
            '1 Kg. Flour also contains almost 13 grams of dietary fiber per cup, which helps to promote healthy digestion.',
        altName: 'ƛ̓iƛ̓ck̓uk',
        funFact:
            'There are many varieties of wheat grown around the world. In the Alberni Valley, oats, barley, and rye are most common.',
        imgPath: Flour,
        whereToBuy: [VanIsle],
    },
    {
        cost: 13,
        name: 'Oatmeal',
        description:
            '1 Kg. Oats are loaded with dietary fiber and have a range of healthy cholesterol-lowering properties.',
        funFact: 'October 28th is National Oatmeal Day.',
        imgPath: Oatmeal,
        whereToBuy: [VanIsle],
    },
]
