import { Recipe as R } from '.'
const LemonyRabbit: R = {
    title: 'Lemony Rabbit',
    ingredients: [
        '1 tablespoon butter or margarine',
        '2 tablespoon vegetable oil',
        '3 ½ lb rabbit ',
        '1/2 cup lemon juice',
        '6 green onions, finely chopped',
        '1/2 teaspoon sage',
        '1/2 teaspoon thyme',
        'Pepper to taste',
    ],
    steps: [
        'In a heavy saucepan, thoroughly heat the butter and vegetable oil. ',
        'Add rabbit and cook until browned. ',
        'Stir in lemon juice, green onions, sage, thyme and pepper to taste. ',
        'Bring to boil, cover, reduce heat and simmer for 1 hour or until tender.',
        'Before serving, sprinkle with chopped parsley.',
    ],
    farm: 'Cottonwood Farm',
}

const BuffaloStew: R = {
    title: 'Another Yummy Water Buffalo Stew',
    ingredients: [
        '2 pounds water buffalo stew',
        '3 tablespoons flour',
        'garlic ',
        '1/2 teaspoon salt',
        '1/2 teaspoon black pepper',
        '3 tablespoons butter',
        '1 onion chopped',
        '1/2 cup red wine optional',
        '6 cups water ',
        '1/4 cup of soya sauce/li',
        '4 tablespoons tomato paste',
        '1 teaspoon dried rosemary or 1 sprig fresh',
        '1-pound potatoes peeled and cubed',
        '4 carrots cut into 1-inch pieces',
        '4 celery stalks cut into 1-inch pieces',
        '2 tablespoons cornstarch',
        '2 tablespoons water',
    ],
    steps: [
        'Combine flour, garlic and salt & pepper. Dredge water buffalo stew meat in flour mixture. ',
        'Heat butter in a large Dutch oven or pot. Cook the beef and onions until browned. ',
        'Add red wine and water while scraping up any brown bits in the pan. ',
        'Stir in all remaining ingredients, add enough water to cover meat. Place in oven at 330 for 2 hours or until meat is tender. Season with salt & pepper to taste. Serve with biscuits, garlic bread and enjoy!',
    ],
    farm: 'Coleman Meadows Farm',
}

const BuffaloPaneerCurry: R = {
    title: 'Buffalo Paneer Curry',
    ingredients: [
        '1 tablespoon butter and left-over WB Roast, diced.',
        ' 1 onion, chopped ',
        '1 garlic clove, crushed ',
        '1 teaspoon grated fresh ginger ',
        '2 tablespoons curry paste (or mild curry paste)',
        '1 1/2 cup stock or water ',
        '1 large carrot, peeled, chopped ',
        '1 stalk celery, chopped ',
        '200 ml coconut milk ',
        '1 apple, peeled and chopped ',
        'Natural Pastures Buffalo Paneer, one third of a package, cubed.',
        'Coriander or parsley leaves, to garnish',
        'Steamed brown medium grain rice (or your favourite rice), to serve ',
    ],
    steps: [
        'Heat oil in a large saucepan over high heat, add the cubed roast and brown in batches. Remove and set aside.',
        'Reduce heat to medium, add onion, garlic and ginger to pan and cook, stirring, for 5-6 minutes.',
        'Add curry paste and cook, stirring, for 2-3 minutes. Return meat to pan, add stock and bring to the boil. Reduce heat and simmer for 30 minutes.',
        'Add the carrot, celery and coconut milk and cook for a further 25 minutes. ',
        'Add Natural Pastures Buffalo Paneer and warm through. Garnish with coriander and serve with rice.',
    ],
    farm: 'Coleman Meadows Farm',
}

const BuffaloChiliConCarne: R = {
    title: 'Water Buffalo Chilli-Con-Carne',
    ingredients: [
        '2 pounds water buffalo ground',
        '2 Tbsp. butter or olive oil',
        '2 medium onions, chopped',
        '3 stalks of celery',
        '2 garlic cloves, minced',
        '2 medium green or coloured peppers, chopped',
        '1½ teaspoons salt',
        '2 Tbsp. chili powder',
        '1/8 teaspoon cayenne pepper (hot according to taste)',
        '1 Tbsp. ground cumin',
        '2 cans (28 ounces each) diced tomatoes, undrained',
        '1 small tin tomato paste',
        '1/4 cup apple cider vinegar',
        '1 can (28 ounces) kidney beans, rinsed and drained',
    ],
    steps: [
        'Brown ground meat, then add vegetables (you can also add corn and diced carrots), for a couple of minutes, then add diced tomatoe, tomatoe paste, kidney beans, spices, salt and pepper.',
        'Simmer for a couple of hours or put in a slow cooker!',
    ],
    farm: 'Coleman Meadows Farm',
}

const ScrambledEggs: R = {
    title: 'Scrambled Eggs with Kale and Mozerella',
    ingredients: [
        ' 1 Tbsp olive oil',
        '1/4 cup of chopped onion',
        'Pinch of red pepper flakes',
        '1 ounce fresh kale, destemmed and thinly sliced (about 1 cup)',
        '4 eggs, beaten',
        '1/2 cup of grated mozzarella cheese',
        '1/2 teaspoon Italian seasoning (dried rosemary, oregano, thyme, basil)',
        ' Salt and freshly ground black pepper to taste',
    ],
    steps: [
        'Heat olive oil in a stick-free frying pan on medium high heat. When the oil is hot, add the chopped onion and red pepper flakes. Cook for one minute.',
        'Add the thinly sliced kale to the pan, toss with the onions and olive oil. Cook for a few minutes, until the kale is just wilted',
        'Lower the heat to medium. Add the beaten eggs to the pan. Stir until the eggs begin to set. Then stir in the shredded mozzarella cheese and Italian seasoning. Remove from heat and continue to stir a few times until the cheese is melted and the eggs are cooked. Sprinkle with salt and pepper to taste.',
    ],
    farm: 'Lettuce Be Farm',
}

const MapleBakedBeans: R = {
    title: 'Maple Baked Beans',
    farm: 'Arrowvale Farm',
    ingredients: [
        '1 ham hock ',
        '1 bag 907 gm white beans ',
        'water for soaking ',
        '1 cup maple syrup ',
        '1/2 cup ketchup ',
        '2 onions coarsley chopped ',
        '1 tablespoon dijon mustard ',
        'salt and pepper to taste ',
    ],
    steps: [
        'Cover the beans with 2 inches of water and soak overnight.',
        'Cover ham hock with water and simmer for 2 or 3 hours until you can take the meat off the bone. Peel the skin off and shred the meat. Discard the skin. Do not throw out the water!',
        'Drain the beans and put then in slow cooker along with the rest of the ingredients, including the ham and ham bone. Top up the ham hock cooking water to 6 cups and add to beans. Cook for 8 or 9 hours on low or 6 or 7 hours on high. Top up with water if necessary.',
        'This is meaty and delicious and a big hit at our Maple Syrup festivities. Best served the next day after beans have soaked up all the juices. Freezes well.',
    ],
}
const LambChops: R = {
    title: 'Lamb Chops with Blackberry Puree',
    farm: 'Cottonwood Farm',
    ingredients: [
        '4-6 Lamb Chops – trimmed ',
        '1 Tbsp. chopped rosemary and a few sprigs for garnish ',
        'Salt and pepper',
        '2 Tbsp. oil',
        '1 cup blackberries – pureed until smooth and ½ cup blackberries left whole for garnish ',
        '½ cup red wine ',
        '½ cup beef stock ',
        '2 Tbsp. balsamic vinegar ',
    ],
    steps: [
        'Sprinkle lamb with rosemary, salt and pepper and press into chops. Heat oil in a pan and cook lamb chops to your taste. Remove from pan. (Keep warm until sauce is ready).',
        'Remove excess fat from pan. Add blackberry puree, wine, stock and vinegar and reduce until lightly thickened, season with salt and pepper.',
        'Strain sauce if too seedy. Spoon sauce over chops and garnish with the whole blackberries and rosemary.',
    ],
}

const MintGlazedLamb: R = {
    title: 'Mint Glazed Lamb Chops',
    ingredients: [
        '1/4 cup cold water',
        '1 tsp cornstarch',
        '3 tbsp finely snipped fresh mint leaves or 4 tsp dried mint, crushed',
        '1 tbsp light corn syrup',
        '1/2 tsp finely grated lemon peel',
        '1/4 tsp salt',
        '4 lamb chops, 3/4 inch thick]',
    ],
    steps: [
        'For glaze, in saucepan combine water and cornstarch. Add mint, corn syrup, lemon peel and salt. Cook and stir till thickened and bubbly.',
        'Place lamb chops on unheated rack of broiler pan. Broil 3-4 inches from heat for 5 minutes. Brush some glaze on chops. Turn chops; broil 5-6 minutes more, brushing occasionally with glaze.',
        'Makes 4 servings.',
    ],
    farm: 'Cottonwood Farm',
}

const LambStew: R = {
    title: 'Springtime Lamb Stew',
    ingredients: [
        '3 lb lean lamb cubed',
        '1 tsp sugar',
        '1 tsp salt',
        '¼ tsp pepper, thyme, rosemary, marjoram',
        '1 bay leaf',
        '¼ cup quick cooking tapioca',
        '1 cup dry red wine',
        '1 can (5 ½ oz) tomato paste',
        '2 large onion cut in wedge',
        '2 cloves garlic minced',
        '3 large potatoes',
        '1 ½ cup baby carrots',
        '1 cup mushrooms',
        '1 cup frozen peas',
    ],
    steps: [
        'In pot combine lamb, sugar salt, pepper, spices, sprinkle with tapioca.',
        'Combine stock, wine and tomatoe paste, pour over lamb mixture. Add onion wedges and garlic, cover and bake in 350 degree oven for one hour stirring occasionally.',
        'Add potatoes, carrots, and mushrooms, cover and bake under meat and veggies are tender, remove bay leaf and stir in peas.',
    ],
    farm: 'Cottonwood Farm',
}

export const ProteinRecipes = [
    LemonyRabbit,
    BuffaloStew,
    BuffaloPaneerCurry,
    BuffaloChiliConCarne,
    ScrambledEggs,
    MapleBakedBeans,
    MintGlazedLamb,
    LambChops,
    LambStew,
]

export {
    LemonyRabbit,
    BuffaloStew,
    BuffaloPaneerCurry,
    BuffaloChiliConCarne,
    ScrambledEggs,
    MapleBakedBeans,
    MintGlazedLamb,
    LambChops,
    LambStew,
}
