import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Button } from 'react-bootstrap'
import FormGroup from '../FormGroup'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const schema = yup.object().shape({
    email: yup.string().label('Email').required(),

    password: yup.string().label('Password').required(),
})

const Notification = styled.div`
    padding-top: 1em;
`

const NoAccount = styled.span`
    margin-top: 1em;
    color: #666666;
    font-size: 1.5em;
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
`

interface Props {
    onSuccess: () => void
}

function SignIn({ onSuccess }: Props) {
    const history = useHistory()

    const [notConfirmed, setNotConfirmed] = useState(false)

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={schema}
            onSubmit={async ({ email, password }, { setSubmitting, resetForm }) => {
                try {
                    setSubmitting(true)
                    const result = await Auth.signIn({
                        username: email,
                        password,
                    })
                    console.log('ConfirmEmail Handler')
                    console.log({ email })
                    console.log(result)
                    onSuccess()
                    // Show success
                } catch (err) {
                    if (err.name === 'UserNotConfirmedException') {
                        setNotConfirmed(true)
                    } else {
                        console.error(err)
                    }
                    setSubmitting(false)
                    // Show error
                }
            }}
        >
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, isSubmitting }) => (
                <div>
                    <Form noValidate onSubmit={handleSubmit}>
                        <FormGroup
                            id="email"
                            label="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            value={values.email}
                            error={errors.email}
                            touched={touched.email}
                        />
                        <FormGroup
                            id="password"
                            label="Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.password}
                            error={errors.password}
                            touched={touched.password}
                        />
                        <Button variant="primary" type="submit" disabled={isSubmitting || !isValid}>
                            Submit
                        </Button>
                    </Form>
                    {notConfirmed && (
                        <Notification>
                            <p>
                                Your email {values.email} has not been confirmed. Check your email for a confirmation
                                link.
                            </p>
                            <Button
                                onClick={() =>
                                    Auth.resendSignUp(values.email).then((r) => console.log({ response: r }))
                                }
                            >
                                Resend Email
                            </Button>
                        </Notification>
                    )}
                    <NoAccount
                        onClick={() => {
                            history.push('/signup')
                        }}
                    >
                        {' '}
                        Create an Account{' '}
                    </NoAccount>
                </div>
            )}
        </Formik>
    )
}

export default SignIn
