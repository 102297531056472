import React from 'react'
import styled from 'styled-components'
import { Carousel } from 'react-bootstrap'
// import HomeArtsWinners from '../assets/HomeArtsWinners.png'
import Alport from '../assets/Alport.jpg'
import AVN from '../assets/AVN.jpg'
import Peak from '../assets/Peak.png'
// import VanIsle from '../assets/VanIsle.jpg'
// import FarmersMarket from '../assets/FarmersMarket.jpg'
import CoastalBridge from '../assets/CoastalGroup.jpg'
import Coop from '../assets/Coop.jpg'
import { useHistory } from 'react-router-dom'

const BoothHeader = styled.div`
    height: 3em;
    width: 10em;
    background-color: white;
    font-size: 2em;
    position: absolute;
    top: 2em;
    left: 5em;
    font-family: roboto;
    font-weight: bold;
`

const BoothImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    height: 15em;
`

const BoothCarousel = styled(Carousel)`
    margin-bottom: 5em;

    div {
        cursor: pointer;
    }

    @media (min-width: 850px) {
        margin-bottom: 6em;
    }
`

function DiscoverBanner() {
    const history = useHistory()

    return (
        <BoothCarousel controls={false} interval={3000} indicators={false}>
            <Carousel.Item onClick={() => history.push('/booth/47083efd-5a70-4d86-bf62-d6847e6cab0e')}>
                <BoothImage src={Peak} />
            </Carousel.Item>
            <Carousel.Item onClick={() => history.push('/booth/914fb727-ad8a-48e8-8632-be332e87a557')}>
                <BoothImage src={AVN} />
            </Carousel.Item>
            <Carousel.Item>
                <BoothImage src={Alport} onClick={() => history.push('/booth/4ce8cb8f-bf09-48bd-856c-443c71e17fe8')} />
            </Carousel.Item>
            <Carousel.Item onClick={() => history.push('/booth/0e90ff43-977b-4a82-8f89-0cf9093f7a9e')}>
                <BoothImage src={CoastalBridge} />
            </Carousel.Item>
            {/* <Carousel.Item>
                <BoothImage src={Coop} />
            </Carousel.Item> */}
        </BoothCarousel>
    )
}

export default DiscoverBanner
