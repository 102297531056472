import React from 'react'
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom'
import LandingVendor from '../landingPages/LandingVendor'
import Create from '../booth/Create'
import Booth from '../booth/Booth'
import GettingStarted from '../booth/GettingStarted'
import NextSteps from '../booth/NextSteps'
import Edit from '../booth/Edit'

function BoothRoutes() {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <Route path={`${path}/getting-started`}>
                <GettingStarted />
            </Route>
            <Route path={`${path}/next-steps`}>
                <NextSteps />
            </Route>
            <Route path={`${path}/create`}>
                <Create />
            </Route>
            <Route path={`${path}/:id/edit`} render={({ match }) => <Edit id={match.params.id} />}></Route>
            <Route path={`${path}/:id`} render={({ match }) => <Booth id={match.params.id} />}></Route>
            <Route path={`${path}`}>
                <LandingVendor />
            </Route>
        </Switch>
    )
}

export default BoothRoutes
