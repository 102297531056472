import React, { useEffect, useRef } from 'react'

interface VideoProps {
    videoLink: string
    height: string
    width: string
}

function FacebookPlayer({ videoLink, height, width }: VideoProps) {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        FB.XFBML.parse(ref.current)
    })

    return (
        <div ref={ref} style={{ width: width, height: height }}>
            <div className="fb-video" data-href={videoLink} data-show-text="false"></div>
        </div>
    )
}

export default FacebookPlayer
