import React from 'react'
import Authentication from '../templates/Authentication'
import { SignUp as Form } from '../molecules/account'
import { Auth } from 'aws-amplify'
import { Button } from 'react-bootstrap'

export default function SignUp() {
    return <Authentication Form={Form} />
    // return <Button onClick={() => Auth.federatedSignIn({})} />
}
