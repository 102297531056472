import React from 'react'
import Form from 'react-bootstrap/Form'
import { FormControlProps } from 'react-bootstrap/FormControl'

interface FormGroupProps {
    id: string
    label: string
    placeholder?: string
    onBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    rows: number
    value: FormControlProps['value'] | boolean
    error?: string
    touched?: boolean
}

function FormTextArea({ id, label, placeholder, onBlur, onChange, rows, value, error, touched }: FormGroupProps) {
    return (
        <Form.Group controlId={id}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                value={typeof value !== 'boolean' ? value : undefined}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={placeholder}
                isInvalid={!!error && !!touched}
                name={id}
                as="textarea"
                rows={rows}
            />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
    )
}

export default FormTextArea
