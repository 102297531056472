const businessIds = [
    '84310f90-85ef-4a81-a8ac-c62ee1b42648',
    'efe488b9-1924-4480-9f37-d46d84db751c',
    '0e90ff43-977b-4a82-8f89-0cf9093f7a9e',
    '47083efd-5a70-4d86-bf62-d6847e6cab0e',
    '4ce8cb8f-bf09-48bd-856c-443c71e17fe8',
    '914fb727-ad8a-48e8-8632-be332e87a557',
    '74390cb5-4b15-4fd8-aca0-071b795b598d',
    '30a0a192-5028-4cc9-ac1d-442589090f9b',
    '34d0e09b-e5fa-4abc-af39-4d2916a25c11',
    'caa14a9b-709f-4fbf-b97a-3818d0283684',
]

const agricultureIds = [
    '36ab7bd1-7721-4da5-bed4-54521d370f68',
    '550e7513-6f9e-4920-aa8d-01a13b9f2f31',
    'be45f6c9-a323-43b0-bd27-c67e4a6f9d6e',
    'ae1c3d01-dfc4-4ba4-a1c7-4b86179c837b',
]

const nonProfitIds: string[] = [
    // '0757ee3d-b4d0-4397-8e05-9ecafac121b3',
    // '1314ae33-eae7-4cb7-98e0-eae7be048aa1',
    // '58130fbc-7521-40eb-9a11-990d5a68433f',
    // '66dd6d6f-92cc-4d29-b291-5fb21eb7f72c',
]

const entertainerIds = ['316b24f3-d7cd-47ce-aee3-f41881a569ea', '92612087-a635-4c74-b64e-98e0f61cc707']

export { businessIds, agricultureIds, nonProfitIds, entertainerIds }
