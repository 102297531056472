import React from 'react'
import Recipe from '../templates/Recipe'
import { Recipe as R } from '.'

const SpanishFlan: R = {
    title: 'Spanish Flan',
    farm: 'Lettuce Be Farm',
    ingredients: [
        '1 cup white sugar',
        '3 large eggs',
        '1(14 ounce) can sweetened condensed milk',
        '1 (12 fluid ounce) can evaporated milk',
        '1 tablespoon vanilla extract ',
    ],
    steps: [
        'Preheat oven to 350 degrees F (175 degrees C).',
        'In a medium saucepan over medium-low heat, melt sugar until liquefied and golden in color. Carefully pour hot syrup into a 9-inch round glass baking dish, turning the dish to evenly coat the bottom and sides. Set aside.',
        'In a large bowl, beat eggs. Beat in condensed milk, evaporated milk and vanilla until smooth. Pour egg mixture into baking dish. Cover with aluminum foil.',
        'Bake in preheated oven 60 minutes. Let cool completely.',
        'To serve, carefully invert on serving plate with edges when completely cool.',
    ],
}

const AppleCream: R = {
    farm: '',
    title: 'Apple Cream Cheese Squares',
    ingredients: [
        'Pastry:',
        '1.5 cups spelt flour',
        '1/3 cups sugar',
        '1/2 tsp vanilla',
        '3/4 cups butter',
        'Filling:',
        '8 oz cream cheese',
        '1/4 cup sugar',
        '1 egg',
        '1/2 tsp vanilla',
        'Topping:',
        '1/3 cup brown sugar',
        '1/2 tsp cinnamon',
        '4 cups apples, finely sliced',
        '1/4 almonds',
    ],
    steps: [
        'Preheat oven to 400 degrees. Butter a 9”x9” baking pan.',
        'Whirl the pastry ingredients in a food processor until crumbly, and press the mixture into the bottom of the pan. Bake for 10 minutes and set aside to cool.',
        'Now whirl the filling ingredients in the food processor until creamy and spoon it over the cooled pastry base.',
        'Finally, mix the toppings and arrange over the filling.',
        'Bake for 20-25 minutes and cool before cutting into squares and serving.',
    ],
}
export const SweetRecipes = [SpanishFlan, AppleCream]
export { SpanishFlan, AppleCream }
