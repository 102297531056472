import React, { useEffect, useState } from 'react'
import { BoothData } from '../data/mock'
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api/lib-esm'
import { boothsByCategory } from '../custom-graphql/myQueries'
import { API } from 'aws-amplify'
import { BoothsByCategoryQuery, Category } from '../API'
import Streams from '../molecules/Streams'
import styled from 'styled-components'
import DiscoverBanner from '../molecules/DiscoverBanner'
import { agricultureIds, businessIds, nonProfitIds, entertainerIds } from '../data/boothIds'
import { shuffleArray } from '../helpers/utils'

const Wrapper = styled.div`
    @media (min-width: 850px) {
        width: 800px;
        margin: auto;
    }
`

function Discover() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [businessBooths, setBusinessBoths] = useState<BoothData[]>()

    const [businessShuffledIds, setBusinessShuffledIds] = useState<string[]>()
    const [agricultureShuffledIds, setAgricultureShuffledIds] = useState<string[]>()
    const [nonProfitShuffledIds, setNonProfitShuffledIds] = useState<string[]>()
    const [entertainerShuffledIds, setEntertainerShuffledIds] = useState<string[]>()

    useEffect(() => {
        ;(async function () {
            setLoading(true)
            try {
                const response = (await API.graphql({
                    query: boothsByCategory,
                    variables: {
                        category: Category.BUSINESS,
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY,
                })) as GraphQLResult<BoothsByCategoryQuery>
                if (response.errors) {
                    setLoading(false)
                    setError(true)
                    console.error({ errors: response.errors })
                    return
                }
                if (response.data?.boothsByCategory?.items && businessBooths) {
                    console.log(response.data)
                    response.data.boothsByCategory.items.forEach((item) => {
                        const {
                            id,
                            owner,
                            category,
                            logo,
                            title,
                            description,
                            images,
                            livestream,
                            youtubeUrl,
                            facebookId,
                            instagramHandle,
                            website,
                        } = item as BoothData

                        console.log(JSON.stringify(item))
                        businessBooths.push({
                            id,
                            owner,
                            category,
                            logo,
                            title,
                            description,
                            images,
                            livestream,
                            youtubeUrl,
                            facebookId,
                            instagramHandle,
                            website,
                        })
                        setBusinessBoths(businessBooths)
                    })
                }
            } catch (err) {
                console.error({ err })
            }
            setLoading(false)
        })()
    }, [])

    useEffect(() => {
        setBusinessShuffledIds(shuffleArray([...businessIds]))
        setAgricultureShuffledIds(shuffleArray([...agricultureIds]))
        setNonProfitShuffledIds(shuffleArray([...nonProfitIds]))
        setEntertainerShuffledIds(shuffleArray([...entertainerIds]))
    }, [])
    return (
        <Wrapper>
            <DiscoverBanner />
            <Streams category="Sponsors" streamIds={businessShuffledIds} />
            <Streams category="Information" streamIds={agricultureShuffledIds} />
            {/* <Streams category="Non Profit Organizations" streamIds={nonProfitShuffledIds} /> */}
            <Streams category="Entertainment" streamIds={entertainerShuffledIds} />
        </Wrapper>
    )
}

export default Discover
