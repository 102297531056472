import { Recipe } from '.'

const AppleSauceGranola: Recipe = {
    title: 'Applesauce Granola',
    ingredients: [
        '5 cups multigrain cereal or oat flakes ',
        '3 cups almonds, coarsely chopped ',
        '1 cup sunflower seeds ',
        '1/2 cup packed brown sugar ',
        '2 tsp ground cinnamon ',
        '1 tsp dried ginger ',
        '1 tsp sea salt ',
        '3/4 cup unsweetened applesauce ',
        '1/2 cup honey ',
        '2 tbsp vegetable oil ',
    ],
    steps: [
        'Preheat oven to 300 degrees. ',
        'Mix together dry ingredients. In small saucepan, heat applesauce, honey and oil. Pour over dry ingredients and spread evenly onto 2 cookie sheets.',
        'Bake for about 45 mins, stirring every 10 minutes until deep golden brown in colour. Remove from oven and cool completely.',
        'Store in airtight container for up to one month. ',
    ],
    farm: 'Burde Street Community Orchard',
}

export const GrainRecipes = [AppleSauceGranola]
export { AppleSauceGranola }
