import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../../molecules/Button'
import { Colors } from '../../helpers/theme'
import { CSSTransition } from 'react-transition-group'
import { Link } from 'react-router-dom'

const Heading = styled.div`
    font-size: 3em;
    min-height: 6em;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Content = styled.div`
    background-color: ${Colors.blueGray};
    color: ${Colors.ivory};
    font-size: 1.5em;
    padding: 0.5em;
    border-radius: 5px;
`

const BlurContent = styled(Content)`
    background: linear-gradient(${Colors.blueGray}ff -31.27%, #ffffffff 100%);
`

const NavButton = styled.div`
    min-height: 3em;
    margin: 0.5em 0;
    button {
        padding: 0 2em;
    }
    button:disabled {
        cursor: auto;
    }
`

const Container = styled.div`
    max-width: 400px;
    margin: auto;
    .body-transition-enter {
        opacity: 0;
    }
    .body-transition-enter-active {
        opacity: 1;
        transition: opacity 400ms;
    }
    .body-transition-exit {
        opacity: 1;
    }
    .body-transition-exit-active {
        opacity: 0;
        transition: opacity 400ms;
        height: 0;
        transition: height 400ms;
    }
`

const textBodies = [
    `We're using Facebook Live streaming and the Myfair.ca website to host a virtual fair!, registration is free for non-business organizations/individuals, and $50 for businesses`,
    `Live streaming is when video is sent over the Internet in real time, without being recorded and stored first. It's like a live radio or TV broadcast, but over the internet.`,
    `Using Facebook Live, live-booth holders (like you) will be able to interact with Facebook audiences in real time, just like you would at a real fair!`,
    `Whats more, is that the people you interact with will have a direct link to your website and social media channels! So make sure you're pasting a link to your livebooth in the description of your Facebook Live stream, and that you’re encouraging audiences to click into it!`,
    `Your booth will be live on the virtual fair website 3 days from September 10-12th. You may go live with Facebook as much or a little as you want during this time, but peak hours will be from 12 pm to 3 pm daily, so make sure you’re live between those times to get the most out of your stream!`,
    `To get your live booth set up, we're going to ask you to provide a bit of information about your endeavour.`,
]

const textHeadings = [
    `Thank you for participating in the Alberni District Virtual Fair!`,
    `What is Live Streaming?`,
    'What is so Good About Live Streaming?',
    'What is so Good About Live Streaming?',
    'When Should you go Live?',
    'Time to Build Your Booth!',
]

function GettingStarted() {
    const [index, setIndex] = useState(0)
    const [contentChange, setContentChange] = useState(false)

    function handleTextChange(idx: number) {
        setContentChange(true)
        setIndex(idx)
    }

    return (
        <Container>
            <CSSTransition in={contentChange} timeout={400} classNames={'body-transition'}>
                <Heading>
                    <span>{textHeadings[index]}</span>
                </Heading>
            </CSSTransition>
            <NavButton onClick={() => handleTextChange(index - 1)}>
                <Button variant="outline-primary" disabled={index === 0} color={Colors.fairRed} hover={Colors.mango}>
                    ⬆
                </Button>
            </NavButton>
            {textBodies.map((bodyText, idx) => (
                <CSSTransition key={idx} in={idx === index} timeout={400} classNames={'body-transition'} unmountOnExit>
                    <Content>
                        <span>{bodyText}</span>
                    </Content>
                </CSSTransition>
            ))}
            {index !== textBodies.length - 1 && (
                <NavButton onClick={() => handleTextChange(index + 1)}>
                    <Button variant="outline-primary" color={Colors.fairRed} hover={Colors.mango}>
                        ⬇
                    </Button>
                </NavButton>
            )}
            {index === textBodies.length - 1 && (
                <NavButton>
                    <Link to="/booth/create">
                        <Button color={Colors.fairRed} hover={Colors.mango}>
                            Create Booth
                        </Button>
                    </Link>
                </NavButton>
            )}
            {index !== textBodies.length - 1 && (
                <BlurContent>
                    <div>
                        <span>{textBodies[index + 1]}</span>
                    </div>
                </BlurContent>
            )}
        </Container>
    )
}

export default GettingStarted
