import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BackgroundTemp from '../assets/BackgroundTemp.svg'
import Live from '../assets/Live.svg'
import { generalGetBooth } from '../custom-graphql/myQueries'
import { BoothData } from '..//data/mock'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api/lib-esm'
import { GetBoothQuery } from '../API'
import { useHistory } from 'react-router-dom'

const StreamBackground = styled.div`
    min-width: 15em;
    max-width: 15em;
    height: 17em;
    background-image: url(${(props: Test) => props.url});
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    cursor: pointer;
`

const StreamTitle = styled.div`
    background: rgba(0, 0, 0, 0.3);
    border-radius: 1.9px;
    font-family: roboto;
    font-weight: bold;
    color: white;
    font-size: 1.8em;
`

const LiveImage = styled.img`
    width: 5em;
    left: 0;
    bottom: 0;
    position: absolute;
    margin: 1em;
`

interface StreamProps {
    id: string
}

interface Test {
    url: string
}

function StreamInfo({ id }: StreamProps) {
    const [booth, setBooth] = useState<BoothData | null>(null)
    const history = useHistory()

    useEffect(() => {
        ;(async function () {
            try {
                const response = (await API.graphql({
                    query: generalGetBooth,
                    variables: { id },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY,
                })) as GraphQLResult<GetBoothQuery>
                if (response.errors) {
                    console.error({ errors: response.errors })
                    return
                }
                if (response.data && response.data.getBooth) {
                    const {
                        id,
                        owner,
                        category,
                        title,
                        description,
                        youtubeUrl,
                        images,
                        livestream,
                        facebookId,
                        instagramHandle,
                        website,
                        logo,
                    } = response.data.getBooth

                    setBooth({
                        id,
                        owner,
                        category,
                        title,
                        description,
                        youtubeUrl,
                        images,
                        livestream,
                        facebookId,
                        instagramHandle,
                        website,
                        logo,
                    })

                    console.log(response.data)
                }
            } catch (err) {
                console.error({ err })
            }
        })()
    }, [id])

    return (
        <StreamBackground
            url={`https://myfair.ca/${booth?.logo}`.replace(/ /g, '%20')}
            onClick={() => {
                history.push(`/booth/${booth?.id}`)
                //hack as it wasn't auto reloading from the booth discovery
                window.location.reload(false)
            }}
        >
            <StreamTitle>{booth?.title}</StreamTitle>
            {/* {id == '44b8f7d3-2276-40f0-8599-f60219429dee' && <LiveImage src={Live} />} */}
        </StreamBackground>
    )
}

export default StreamInfo
