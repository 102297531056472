import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Button } from 'react-bootstrap'
import FormGroup from '../FormGroup'

const schema = yup.object().shape({
    code: yup.string().required('Please enter the code you received in your email.'),
})

function ConfirmEmail() {
    return (
        <Formik
            initialValues={{ code: '' }}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                    setSubmitting(true)
                    // const result = await Auth.signUp({
                    //     username: values.email,
                    //     password: values.password,
                    //     attributes: {
                    //         email: values.email,
                    //         name: `${values.firstName} ${values.lastName}`,
                    //     }
                    // })
                    console.log('ConfirmEmail Handler')
                    console.log(values)
                    // Show success
                } catch (err) {
                    // Show error
                } finally {
                    setSubmitting(false)
                }
            }}
        >
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <FormGroup
                        id="confirmEmail"
                        label="Confirm Email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.code}
                        error={errors.code}
                        touched={touched.code}
                    />
                    <Button variant="primary" type="submit" disabled={isSubmitting || !isValid}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

export default ConfirmEmail
