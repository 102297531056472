import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Colors } from '../helpers/theme'
import { Card } from 'react-bootstrap'
import { Recipe as RecipeData } from '../recipes'
import { DairyRecipes } from './dairy'
import { ProteinRecipes } from './protein'
import { VeggieRecipes } from './veggie'
import { GrainRecipes } from './grain'
import { SweetRecipes } from './sweet'
import Button from '../molecules/Button'

const RecipesContainer = styled.div`
    background-color: ${Colors.ivory};
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

const IngredientList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const Header = styled.div`
    background-color: ${Colors.fairRed};
    color: ${Colors.ivory};
    width: 100%;
    padding: 2em;
    h3 {
        font-size: 3em;
    }
    p {
        font-size: 1.5em;
        font-weight: 700;
    }
    span {
        font-size: 2em;
    }
`

const Footer = styled.div`
    width: 100%;
    padding: 2em;
    background-color: ${Colors.fairRed};
    color: ${Colors.ivory};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    label {
        font-size: 1.5em;
    }
`

export const AllRecipes = [...DairyRecipes, ...ProteinRecipes, ...VeggieRecipes, ...GrainRecipes, ...SweetRecipes]

function RecipeCard({ title, farm, ingredients }: RecipeData) {
    return (
        <Card style={{ width: '18rem', margin: '1em' }}>
            <Card.Body style={{ display: 'flex', flexFlow: 'column wrap' }}>
                <Card.Title>{title}</Card.Title>
                {farm && <Card.Subtitle style={{ marginBottom: '0.5em' }}>{farm}</Card.Subtitle>}
                <Card.Text>Ingredients: </Card.Text>
                <IngredientList>
                    {ingredients.map((ingredient) => (
                        <li key={ingredient}>{ingredient}</li>
                    ))}
                </IngredientList>
            </Card.Body>
            <Card.Footer>
                <Link to={`/recipes/${title}`}>
                    <Button color={Colors.fairRed}>See Full Recipe</Button>
                </Link>{' '}
            </Card.Footer>
        </Card>
    )
}

function ItemGrid({ recipes }: { recipes: RecipeData[] }) {
    return (
        <div style={{ display: 'flex', flexFlow: 'row wrap', width: '98%', justifyContent: 'center' }}>
            {recipes.map((recipe) => (
                <RecipeCard
                    key={recipe.title}
                    title={recipe.title}
                    farm={recipe.farm}
                    steps={recipe.steps}
                    ingredients={recipe.ingredients}
                />
            ))}
        </div>
    )
}

function Recipes() {
    return (
        <RecipesContainer>
            <Header>
                <h1>Great Recipes from Local Farms!</h1>
                <p>
                    Checkout these awesome recipes from local farms! While you&apos;re at it, checkout our grocery event
                    to get a chance to win $200 in local farm fresh groceries to make some of these recipes! <br />
                    <Link to="/shop">
                        <Button color={Colors.green}>Enter to Win $200 in Groceries</Button>
                    </Link>
                </p>
            </Header>
            <ItemGrid recipes={AllRecipes}></ItemGrid>
            <Footer></Footer>
        </RecipesContainer>
    )
}

export default Recipes
