import React from 'react'
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom'
import Recipe from '../../templates/Recipe'
import { Recipe as RecipeData } from '../../recipes'
import Recipes from '../../recipes/Recipes'
import { AllRecipes } from '../../recipes/Recipes'
function getRecipeFromTitle(title: string): RecipeData {
    if (typeof title !== 'string') {
        return {
            title: '',
            farm: '',
            ingredients: [],
            steps: [],
        }
    }
    return (
        AllRecipes.find((recipe) => recipe.title === title) || {
            title: 'Recipe not found.',
            farm: '',
            ingredients: [],
            steps: [],
        }
    )
}

function RecipeRoutes() {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <Route
                path={`${path}/:id`}
                render={({ match }) => {
                    return <Recipe {...getRecipeFromTitle(match.params.id)}></Recipe>
                }}
            />
            <Route path={`${path}`}>
                <Recipes />
            </Route>
        </Switch>
    )
}

export default RecipeRoutes
