import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Item } from '../../data/item'

import { Card, CardDeck, Form, Nav } from 'react-bootstrap'
import Button from '../Button'
import { Colors } from '../../helpers/theme'

const ItemCardControl = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    // button {
    //     font-size: 0.8em;
    // }
`
const StoreList = styled.ul`
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
`

interface Props {
    items: Item[]
    addItem: (item: Item, quantity: number) => void
    remainingBalance: number
}

function ItemCard({
    item,
    addItem,
    remainingBalance,
}: {
    item: Item
    addItem: (amount: number) => void
    remainingBalance: number
}) {
    const [amount, setAmount] = useState('1')
    const [tabKey, setTabKey] = useState('#info')
    return (
        <Card style={{ width: '18rem', margin: '1em' }}>
            <Card.Img variant="top" src={item.imgPath} />
            <Card.Header>
                <Nav variant="tabs" activeKey={tabKey} onSelect={(e: string) => setTabKey(e)}>
                    <Nav.Item>
                        <Nav.Link eventKey="#info">Info</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="#funfact">Fun Facts</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="#wheretobuy">Where to Buy</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Card.Header>
            <Card.Body style={{ display: 'flex', flexFlow: 'column wrap' }}>
                <Card.Title>{item.name}</Card.Title>
                {item.altName && <Card.Subtitle style={{ marginBottom: '0.5em' }}>{item.altName}</Card.Subtitle>}
                {tabKey === '#info' && (
                    <div>
                        <Card.Subtitle>${item.cost}</Card.Subtitle>
                        <Card.Text>{item.description}</Card.Text>
                    </div>
                )}
                {tabKey == '#funfact' && (
                    <div>
                        <Card.Text>{item.funFact}</Card.Text>
                    </div>
                )}
                {tabKey == '#wheretobuy' && (
                    <StoreList>
                        {item.whereToBuy.map((store) => (
                            <li key={store.name}>{store.name}</li>
                        ))}
                    </StoreList>
                )}
                <Form style={{ marginTop: 'auto' }}>
                    <ItemCardControl>
                        <Form.Control
                            type="number"
                            placeholder="quantity"
                            style={{ width: '50%' }}
                            value={amount}
                            isInvalid={isNaN(Number(amount))}
                            onChange={(event) => setAmount(event.target.value)}
                        />
                        <div onClick={() => addItem(Math.floor(Number(amount)))} style={{ marginRight: '0.5em' }}>
                            <Button disabled={remainingBalance < item.cost * Number(amount)} color={Colors.fairRed}>
                                Add Item
                            </Button>
                        </div>
                    </ItemCardControl>
                </Form>
            </Card.Body>
        </Card>
    )
}

function ItemGrid({ items, addItem, remainingBalance }: Props) {
    return (
        <div style={{ display: 'flex', flexFlow: 'row wrap', width: '98%', justifyContent: 'center' }}>
            {items.map((item) => (
                <ItemCard
                    key={item.name}
                    item={item}
                    remainingBalance={remainingBalance}
                    addItem={(quantity) => addItem(item, quantity)}
                />
            ))}
        </div>
    )
}

export default ItemGrid
