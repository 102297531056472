import React from 'react'
import { Switch, Route, useRouteMatch, RouteComponentProps } from 'react-router-dom'
import Create from './Create'
import Booth from './Booth'

function Vendor() {
    const { path } = useRouteMatch()
    return (
        <div>
            <Switch>
                <Route path={`${path}/create`}>
                    <Create />
                </Route>
                <Route
                    path={`${path}/booth/:boothId`}
                    render={(props: RouteComponentProps<{ boothId: string }>) => {
                        return <Booth id={props.match.params.boothId} />
                    }}
                />
            </Switch>
        </div>
    )
}

export default Vendor
