import React from 'react'
import YouTube from 'react-youtube'

interface PlayerProps {
    videoId: string
    height: string
    width: string
    onPlay: () => void
    onPause: () => void
    onEnd: () => void
}

function YoutubePlayer({ videoId, height, width, onPlay, onPause, onEnd }: PlayerProps) {
    const opts = {
        height,
        width,
        PlayerVars: {
            autoplay: 1,
        },
    }
    function onReady(event: any) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo()
    }

    return (
        <YouTube
            videoId={videoId}
            onReady={onReady}
            opts={opts}
            onPlay={onPlay}
            onPause={onPause}
            onEnd={onEnd}
        ></YouTube>
    )
}

export default YoutubePlayer
