import React from 'react'
import styled from 'styled-components'
import StreamInfo from '../molecules/StreamInfo'

const StreamCategory = styled.div`
    min-width: 100%;
    display: flex;
    overflow-x: auto;

    ::-webkit-scrollbar {
        display: true;
    }

    > div {
        margin: 1em;
    }
`

const StreamHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    h2 {
        margin-left: 0.5em;
        margin-bottom: 0em;
        font-weight: bold;
    }

    span {
        margin-right: 0.5em;
        font-size: 1.5em;
    }
`

const BottomLine = styled.div`
    height: 1px;
    background: #c5c5c5;
    width: 80%;
    margin: auto;
    margin-bottom: 2em;
`

interface StreamsProps {
    category?: string
    streamIds?: Array<string>
}

function Streams({ category, streamIds }: StreamsProps) {
    return (
        <div>
            <StreamHeader>
                <h2>{category}</h2>
                {/* <span>See All</span> */}
            </StreamHeader>
            <StreamCategory>
                {streamIds?.map((id) => {
                    return <StreamInfo id={id} />
                })}
            </StreamCategory>
            <BottomLine />
        </div>
    )
}

export default Streams
